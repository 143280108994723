import {
  Candle,
  HeadlineTimeRanges,
  LeaderboardTimeRanges,
  Sorts,
  TimeRanges,
  TokenListSort,
} from "..";
import { Granularity } from "../nfts";
import { SortDirection } from "../nfts/constants";

export interface DefiToken {
  id: string;
  address: string;
  name: string;
  shortName?: string;
  slug: string;
  symbol?: string;
}

export interface IdArg {
  id: string;
  slug?: string;
}

export interface DefiTokenActiveUserOverTimeRequestParams {
  // token filter used to get the mint / token key
  tokenFilter: string;
  // client passes timeRangeFilter: all-time, 1-month, 1-week, etc
  timeRangeFilter: string;
  // number of results
  pageSize?: number;
}

export interface DefiTokenNewUserOverTimeRequestParams {
  // token filter used to get the mint / token key
  tokenFilter: string;
  // client passes timeRangeFilter: all-time, 1-month, 1-week, etc
  timeRangeFilter: string;
  // number of results
  pageSize?: number;
}

export interface DefiTokenByTopProtocolsVolumeRequestParams {
  // token filter used to get list of program ids
  tokenFilter: string;
}

export interface DefiTokenByTopProtocolsVolumeResponse {
  results: DefiTokenByTopProtocolVolume[];
}

export interface DefiTokenByTopProtocolVolume {
  // name of token
  name: string;
  // address of token
  address: string;
  // volume token moved in last 24 hours
  vol24hr: string;
  // volume change of token in last 24 hours
  change24hr: string;
  //   slug for each protocol
  slug: string;
}

// cols for Token Community Wealth By Token table
export type DefiTokenCommunityTokenWealth = {
  token: string;
  maxBalance: string;
  medianBalance: string;
  meanBalance: string;
  name: string;
  slug: string;
};

// Results from the query
export type DefiTokenCommunityTokenWealthQueryResult = {
  // mint_one is the token in consideration- i.e. passing thru params
  mint_one: string;
  //   mint_two is the token mint showing on token wealth chart
  mint_two: string;
  max_balance_usd: string;
  median_balance_usd: string;
  mean_balance_usd: string;
  name: string;
  slug: string;
};

export type DefiTokenCommunityTokenWealthParams = {
  tokenFilter: string;
};

export type DefiTokenCommunityTokenWealthResponse = {
  data: DefiTokenCommunityTokenWealth[];
};

export interface DefiTokenDailyActiveUsersByWalletSizeRequestParams {
  tokenFilter: string;
}

export interface DefiTokenDailyVolumeByWalletSizeRequestParams {
  tokenFilter: string;
}

export interface TokenPriceCandlesRequestParams {
  // The token to filter for
  tokenId: string;
  // The start of the time range in seconds since the epoch
  startSeconds: number;
  // The end of the time range in seconds since the epoch
  endSeconds: number;
  // The number of bars the client wants, which should take precedence over the
  // time range if provided
  countRequested?: number;
  // The granularity of the time interval for the bars.
  granularity: Granularity;
}

export interface TokenPriceCandlesResponse {
  candles: Candle[];
}

export interface TokenFeedRequestParams {
  // Token filter
  tokenFilter: string;

  // The time period for results.
  timeRangeFilter: TimeRanges;

  // Number of results
  pageSize?: number;

  // Results to skip
  offset?: number;
}

// TODO: deprecate leaderboard v2, routing, and token stats tables
// Then, make optional properties here not optional
export type TokenListItems = {
  slug: string;
  name: string;
  id: string;
  mint: string;
  symbol: string;
  address: string;
  decimals: number;

  netNewUsers: number;
  activeUserCount: number;
  totalHolders?: number;

  priceUsd: number;
  priceUsdChange: number;

  volumeSwapUsd: number;
  percentVolumeSwapUsd: number;

  programCount1D?: number;

  buyers?: number;
  sellers?: number;
};

export interface GetTokenRequest {
  /** ID of the token to retrieve. Note: cannot use both `slug` and `id */
  id?: string;
  /** Slug of the token to retrieve. Note: cannot use both `slug` and `id */
  slug?: string;
  /** What time range to retrieve data for (defaults to ONE_DAY) */
  timeRangeFilter?: HeadlineTimeRanges | LeaderboardTimeRanges;
}

export interface GetTokenResponse {
  /** Token returned from the defi_token_leaderboard_v3 */
  token: TokenListItems;
  /** The total number of rows returned from the query. Used for pagination. */
  totalRowCount: number;
}

export interface ListTokensResponse {
  /** An array of tokens returned from defi_token_leaderboard_v3 */
  tokens: TokenListItems[];
  /** The total number of rows returned from the query. Used for pagination. */
  totalRowCount: number;
}

export interface ListTokensRequest {
  nextPageToken?: string;
  pageSize: number;
  offset?: number;
  /** Filter assets by name. This gets passed to an SQL `LIKE` clause. */
  nameFilter?: string;
  // ToDo: Remove Sorts type from sort? once we migrate all types to TokenListSort
  sort?: Sorts | TokenListSort;
  timeRangeFilter?: HeadlineTimeRanges | LeaderboardTimeRanges;
  sortDirection?: SortDirection;
  slug?: string;
  id?: string;

  arrayOfMints?: string;
}

export enum SwapDirection {
  UNKNOWN = "UNKNOWN",
  SOLD = "SOLD",
  BOUGHT = "BOUGHT",
}

export interface TokenSwap {
  time: string;
  direction: SwapDirection;
  // Mint address
  token: string;
  usdcValue: number;
  usdcRate: number;
  // This is an ordinal number of the swap before or after 5 min from the last updated time.
  // Used for displaying results before or after that time.
  timeWindowCounter?: number;
  // The time of the latest recorded swap
  lastSwapTime?: string;
  amount: number;
}

export interface TokenSwapsResponse {
  swaps: TokenSwap[];
  lastUpdatedAt: string;
}

export interface TokenSwapsRequestParams {
  // Token mint
  tokenId: string;

  // Number of swaps requested.
  // Data is based on swaps for past 15 minutes, aggregated by second and direction, yielding maximum 2 swaps per second.
  // Maximum 610 rows available per token -- i.e., for the most popular token it may be 2 swaps for 5 min worth of seconds, + 10 swaps to show before that.
  countRequested: number;
}

export enum TokenTopWalletsSort {
  HOLDING = "HOLDING",
  VOLUME = "VOLUME",
}
