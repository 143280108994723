import { AxiosRequestConfig } from "axios";
import { DefiTokens } from "../model/aggregations";
import {
  GetTokenRequest,
  GetTokenResponse,
  ListTokensRequest,
  ListTokensResponse,
  TokenSwapsRequestParams,
  TokenSwapsResponse,
} from "../model/aggregations/defiTokens";
import enhancedAxios from "./requestUtil";

export class DefiTokensClient implements DefiTokens.DefiTokensService {
  getTokenV3(args: {
    params: GetTokenRequest;
  }): Promise<DefiTokens.GetTokenResponse> {
    return this.get<GetTokenResponse>("getTokenV3", args);
  }

  listTokensV3(args?: {
    params: ListTokensRequest;
  }): Promise<ListTokensResponse> {
    return this.get<ListTokensResponse>("listTokensV3", args);
  }

  tokenSwaps(args: {
    params: TokenSwapsRequestParams;
  }): Promise<TokenSwapsResponse> {
    return this.get<TokenSwapsResponse>("tokenSwaps", args);
  }

  async get<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.get(
      `/api/aggregations/tokens/${route}`,
      args,
    );
    return response.data;
  }
}
