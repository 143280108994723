import type { AxiosRequestConfig } from "axios";
import type { DataSubscription } from "model/user/DataStreamingModel";
import type {
  DynamoPaginationParams,
  DynamoPaginationResponse,
} from "services/DataSubscriptionService/types";
import { BaseAuthTokenClient } from "./baseAuthTokenClient";
import enhancedAxios from "./requestUtil";

const _baseUri = "/api/dataSubscription";

const _join = (base: string, route: string) =>
  route ? `${base}/${route}` : base;

export class DataSubscriptionClient extends BaseAuthTokenClient {
  async get<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.get(
      _join(_baseUri, route),
      this.getAuthToken(args),
    );
    return response.data;
  }
  async post<T, TData>(
    route: string,
    data: TData,
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await enhancedAxios.post(
      _join(_baseUri, route),
      data,
      this.getAuthToken(args),
    );
    return response.data;
  }

  async put<T, TData>(
    route: string,
    data: TData,
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await enhancedAxios.put(
      _join(_baseUri, route),
      data,
      this.getAuthToken(args),
    );
    return response.data;
  }

  async delete<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.delete(
      _join(_baseUri, route),
      this.getAuthToken(args),
    );
    return response.data;
  }

  async getAll(): Promise<DataSubscription[]> {
    return await this.get("");
  }

  async getById(id: string): Promise<DataSubscription> {
    return await this.get(id);
  }

  async create(data: Partial<DataSubscription>): Promise<DataSubscription> {
    return await this.post("", data);
  }

  async update(data: Partial<DataSubscription>): Promise<DataSubscription> {
    return await this.put(data.id || "", data);
  }

  async remove(id: string): Promise<DataSubscription> {
    return await this.delete(id);
  }

  async getByPagination(
    params: DynamoPaginationParams,
  ): Promise<DynamoPaginationResponse<DataSubscription[] | undefined>> {
    const args = { params };
    return await this.get("pagination", args);
  }
}
