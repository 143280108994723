import ConvertableUTC from "components/ChartV2/util/ConvertableUTC";
import { PortfolioDataByMintsQueryResult } from "queries/aggregations/nft";
import { PortfolioTokenPricesQueryResult } from "queries/aggregations/token";
import {
  BasicAggregationResult,
  Candle,
  HeadlineTimeRanges,
  LeaderboardTimeRanges,
  SeriesY,
  Sorts,
  TimeRanges,
} from "..";
import { WalletMeta } from "../wallets";
import { SmartMinterSort, SortDirection } from "./constants";

export type NftKeyStats = {
  id: string;
  displayName: string;
  numNFTs: number;
  distinctCurrentOwners: number;
  distinctOwnersAllTime: number;
  meanSOL: number;
  medianSOL: number;
  avgWashScore: number;
  minWashScore: number;
  maxWashScore: number;
  washIndexDisplayName: string;
};

export type Market = {
  name: string;
};

export type MarketActivity = {
  // The market
  market: Market;
  // The timerange of the data. We avoid sending it all in one blob.
  timeRange: TimeRanges;
  // Sum of transaction volume in this timeframe, in SOL.
  volumeSol: number;
  // Number of transactions in this timeframe.
  transactionsCount: number;
  // Number of distinct buyers in this timeframe.
  buyersCount: number;
  // Number of distinct sellers in this timeframe.
  sellersCount: number;
  // Number of total users in this timeframe.
  usersCount: number;
  // Percentage of total market activity volume across all accounted for markets, on a scale of 0-1.
  marketShare: number;
};

export interface MarketActivityRequest {
  // The timerange of data desired.
  // We avoid sending data for all timeranges because the user might not need it.
  timeRange: TimeRanges;
}

export interface MarketActivityResponse {
  activity: MarketActivity[];
}

export type TokenHoldingStats = {
  address: string;
  id: string;
  name: string;
  slug: string;
  symbol: string;
  num: number;
  mean: number;
  median: number;
};

export type TokenHoldingStatsResp = {
  data: TokenHoldingStats[];
};

export type TopHolder = {
  ownerAccount: string;
  amount: number;
};

export type TopHolderResponse = {
  data: TopHolder[];
};

export type HoldersBySizeResponse = {
  result: BasicAggregationResult<string, SeriesY<number>>;
};

/** Other nft projects who's owners overlap */
export type NFTOverlap = {
  nft: Nft;
  owners: number;
  amountOwnedOriginalCollection: number;
  amountOwnedOverlapCollection: number;
};

export type NFTOverlapRow = {
  symbol: string;
  owners: string;
  ownedOverlap: string;
  ownedOriginal: string;
};

export type NFTOverlapQueryResult = {
  id: string;
  name: string;
  slug: string;
  owners: string;
  amount_owned_original_collection: string;
  amount_owned_overlap_collection: string;
};

export type NFTOverlapResponse = {
  data: NFTOverlap[];
};

export type GetNftResponse = {
  nft: Nft;
};

export type Nft = {
  // Collection Id
  id: string;
  // Collection slug
  slug: string;
  // Collection name
  name: string;
  // Collection Image
  collectionImageUri?: string;
  // Individual NFT mint
  mint?: string;
};

export interface INftSummary {
  name: string;
  id: string;
  slug: string;
  supply: number;
  smartNetflowScore: number;
  buyersCount: number;
  sellersCount: number;
  averageWashScore: number;

  floorPrice: number | null;
  volume: number | null;
  floorPriceChange: number | null;
  volumeChange: number | null;
  floorPriceDelta: number;
  volumeDelta: number;
}

/**
 * Basic metadata on an NFT for listing NFTs.
 */
export interface NftSummary {
  name: string;
  id: string;
  slug: string;
  supply: number;
  smartNetflowScore: number;
  buyersCount: number;
  sellersCount: number;
  averageWashScore: number;

  floorPrice: number;
  volume: number;
  floorPriceChange: number | null;
  volumeChange: number | null;
  floorPriceDelta?: number;
  volumeDelta: number | null;

  // metadata Fields
  narrative: string;

  // mobile
  listingCount?: number;
  marketCapUsd?: number;

  avgPriceSol?: number;
  currentOwnerCount?: number;

  mintPriceMode?: number;
}

/**
 * Request params for {@link GetNftSummary}
 */
export interface GetNftSummaryRequestParams {
  // The collection to get the summary for
  collectionId: string;
}

/**
 * Response for {@link GetNftSummary}
 */
export interface GetNftSummaryResponse {
  // The summary if found.
  nftSummary: NftSummary;
}

/**
 * Response for listing NFTs
 */
export interface ListNftResponse {
  nfts: NftSummary[];
  totalRowCount?: number;
}

export interface ListINftResponse {
  nfts: INftSummary[];
  nextPageToken: string;
}

/**
 * Request params for listing NFTs
 *
 * nextPageToken: gets new page of results
 * nameFilter?: filtering our global search by name
 * sort?: Sorts: Sorting nftList SQL query by these enums
 * timeRangeFilter?: filtering headlines by these timerange enums
 * subsequentSort?: Additional sorting on floorPriceChange desc ran AFTER the SQL result
 */
export interface ListNftRequestParams {
  nextPageToken: string;
  pageSize: number;
  offset?: number;
  nameFilter?: string;
  sort?: Sorts;
  sortDirection?: SortDirection;
  timeRangeFilter?: HeadlineTimeRanges | LeaderboardTimeRanges;
  subsequentSort?: Sorts;
  arrayOfIds?: string;
}

export interface NFTargs {
  id: string;
}

/**
 * Response for NftOwnershipAllTime
 */
export interface NftOwnershipAllTimeResponse {
  // The number of wallets that have ever held the given NFT(s), over time.
  data: BasicAggregationResult<number, number>;
}

/**
 * Request params for NftOwnershipAllTime.
 */
export interface NftOwnershipAllTimeRequestParams {
  // The collectionId to filter for.
  collectionId?: string;

  // Returns a count of owners who have >1 transaction.
  multipleTransactions?: string;
}

/**
 * Response for NftHoldersByPeriod
 */
export interface NftHoldersByPeriodResponse {
  // The number of current holders of a given NFT, by how long they have held it.
  results: BasicAggregationResult<string, NftHoldersByPeriodResponseSeriesY>;
}

/**
 * Request params for NftHoldersByPeriod
 */
export interface NftHoldersByPeriodRequestParams {
  collectionId: string;
}

export const WalletCountKey = "walletCount";

export interface NftHoldersByPeriodResponseSeriesY extends SeriesY<number> {
  [WalletCountKey]: number;
}

export const enum Granularity {
  OneMin = "oneMin",
  FiveMin = "fiveMin",
  TenMin = "tenMin",
  Hour = "hour",
  Day = "day",
  Week = "week",
  Month = "month",
}

/**
 * Rquest for NftTransactingWallets
 */
export interface NftTransactingWalletsRequestParams {
  collectionId: string;
  granularity: Granularity;
}

/**
 * Response for NftTransactingWallets
 */
export interface NftTransactingWalletsResponse {
  results: BasicAggregationResult<number, number>;
}

/**
 * Request params for NftDistinctOwners.
 */
export interface NftDistinctOwnersRequestParams {
  // The collectionId to filter for.
  collectionId?: string;

  // Returns owners who have >1 NFT.
  multipleNfts?: boolean;

  // The time period for results.
  timeRangeFilter: string;
}

/**
 * Response for NftDistinctOwners
 */
export interface NftDistinctOwnersResponse {
  // The number of distinct wallets that have held the given NFT(s).
  data: BasicAggregationResult<number, SeriesY<number>>;
}

export enum PriceType {
  UNKNOWN = "UNKNOWN",
  MEAN = "MEAN",
  MEDIAN = "MEDIAN",
  MODE = "MODE",
}

/**
 * Request params for NftPriceHistory.
 */
export interface NftPriceHistoryRequest {
  // The collectionId to filter for.
  collectionId?: string;

  // The type of value to return.
  priceType: PriceType;

  // The time range to look at. Defaults to all time.
  timeRangeFilter?: string;
}

/**
 * Response for NftPriceHistory
 */
export interface NftPriceHistoryResponse {
  // The sale price over time.
  data: BasicAggregationResult<string, number>;
}

/**
 * Request params for NftSalesVolume.
 */
export interface NftSalesVolumeRequest {
  // The collectionId to filter for.
  collectionId?: string;

  // The time range to look at. Defaults to all time.
  timeRangeFilter?: string;
}

/**
 * Response for NftSalesVolume
 */
export interface NftSalesVolumeResponse {
  // The sale price over time.
  data: BasicAggregationResult<string, number>;
}

/**
 * Response for NftMints
 */
export interface NftMintsResponse {
  // The number of NFT collections over time.
  data: BasicAggregationResult<number, number>;
}

/**
 * Request params for NftMints
 */
export interface NftMintsRequestParams {
  // The time range to look at. Defaults to all time.
  timeRangeFilter?: string;
}

/**
 * Response for NftTransactionsByProgram
 */
export interface NftTransactionsByProgramResponse {
  // Transactions by program where they transpired
  data: BasicAggregationResult<number, SeriesY<number>>;
}

/**
 * Request params for NftTransactionsByProgram
 */
export interface NftTransactionsByProgramRequestParams {
  // The time range to look at. Defaults to all time.
  timeRangeFilter?: string;
}

/**
 * Request params for NftFloorPrice
 */
export interface NftFloorPriceRequestParams {
  // The collection to get prices for
  //
  // Required.
  collectionId: string;

  // The time period for results.
  timeRangeFilter: TimeRanges;
}

/**
 * Response type for NftFloorPrice
 */
export interface NftFloorPriceResponse {
  // Floor price as a time series.
  result: BasicAggregationResult<number, number>;
}
/**
 * Request params for NftVolumeByWash
 */
export interface NftVolumeByWashRequestParams {
  // The collection to get volume for.
  //
  // Required.
  collectionId: string;

  // The time period for results.
  timeRangeFilter: TimeRanges;
}

/**
 * Response type for NftVolumeByWash
 */
export interface NftVolumeByWashResponse {
  // Volume over time by wash nature.
  result: BasicAggregationResult<number, SeriesY<number>>;
}

/**
 * Request params for MintsByCostBasis.
 */
export interface MintsByCostBasisRequestParams {
  // The collection to get data for.
  //
  // Required.
  collectionId: string;
}

/**
 * Response type for MintsByCostBasis
 */
export interface MintsByCostBasisResponse {
  result: BasicAggregationResult<string, SeriesY<number>>;
}

export interface WashTradingRequestParams {
  collectionId: string;
  pageSize?: number;
}

/**
 * A type B suspected cyclical trade, i.e. wash trade.
 *
 * Describes a pattern with 2 steps:
 *  - Wallet A sends SOL to Wallet B on the side
 *  - Wallet A or B buys NFT 1 from the other
 *
 * This pattern can imply the wallet that send $ is sneakily subsidizing the purchase.
 * Such behavior is known as wash trading and can manipulate asset volume and price.
 */
export interface SuspectedCycleTypeB {
  // The NFT collection ID.
  collection?: string;

  // The NFT collection name.
  name?: string;

  // The NFT ID.
  // mint: string;

  // Wash trading score.
  index: string;

  // The number of wash trading pairs overall for this collection.
  collectionWashPairs: string;

  // This wallet bought the NFT from the wash seller.
  washBuyer: WalletMeta;

  // This wallet sold the NFT to the wash buyer.
  washSeller: WalletMeta;

  // The date the wash sale happened.
  washSaleDate: string;

  // The price of the NFT in the wash sale
  washSalePrice: string;

  // This wallet sent currency to the currencyReceiver.
  currencySender: WalletMeta;

  // This wallet received currency from the currencySender.
  currencyReceiver: WalletMeta;

  // The day one wallet sent USD to the other wallet on the side.
  currencyTransferDate: string;

  // The amount the currency sender sent.
  currencyTransferAmount: string;

  // The type of currency sent.
  currencyTransferCoin: string;
}

export const enum TransactionType {
  CURRENCY_TRANSFER = "CURRENCY_TRANSFER",
  NFT_TRANSFER = "NFT_TRANSFER",
  SALE = "SALE",
}

export interface Transaction {
  // The type of transaction
  transactionType: TransactionType;

  // Date of the transaction
  date: string;

  // Currency transfer if relevant
  currency?: {
    // Who got money
    currencyRecipient: WalletMeta;

    // Who sent money
    currencySender: WalletMeta;

    // The amount of currency sent, e.g. price of the transaction
    // Empty if this is an NFT transfer
    currencyAmount: string;

    // The type of currency
    // Empty if this is an NFT transfer
    currencyName: string;
  };

  // The nft info if relevant
  // Empty if this is an currency transfer
  nft?: {
    // Who got the NFT
    nftRecipient: WalletMeta;

    // Who sent the NFT
    nftSender: WalletMeta;

    // The NFT collection ID.
    collection: string;

    // The NFT collection name.
    name: string;

    // The NFT ID. => we don't want to send this to the frontend
    // mint: string;
  };
}

/**
 * A type C suspected cyclical trade, i.e. wash trade.
 *
 * TypeC involves 3 categories (shown in the Category column)
 * 1) A buys from B with a private transfer of the same mint from A to B
 * 2) A buys from B with a private transfer of the same mint from B to A
 * 3) A buys from B and B buys from A for the same mint.
 *
 * This pattern can imply the wallet that send $ is sneakily subsidizing the purchase.
 * Such behavior is known as wash trading and can manipulate asset volume and price.
 */
export interface SuspectedCycleTypeC {
  // The initial purchase
  transactionOne: Transaction;

  // The subsequent sale or transfer
  transactionTwo: Transaction;

  // The category of type C trading this is.
  category: string;
}

/**
 * A type D suspected cyclical trade, i.e. wash trade.
 *
 * Type D involves 2 behaviors (botlike):
 * 1) A buys and sells with a private transfer of the same mint, within 10 min., at the same price.
 * 2) There are two transactions of a mint within 10 min. at a price similar to at least the thousandths decimal.
 */
export interface SuspectedCycleTypeD {
  transactionOne: Transaction;
  transactionTwo: Transaction;
}

/**
 * Request type for GetWashTradingTypeD
 */
export interface GetWashTradingTypeDRequestParams {
  // Filters results to the provided wallet identifier.
  // Supports both raw wallet addresses and internal wallet IDs.
  //
  // One of walletId or collectionId is required.
  walletId?: string;

  // Filters results to the provided NFT collection id.
  //
  // One of walletId or collectionId is required.
  collectionId?: string;

  // Number of results to retrieve. Returns up to this number of results.
  pageSize?: number;
}

/**
 * Response type for GetWashTradingTypeD
 */
export interface GetWashTradingTypeDResponse {
  typeDResults: SuspectedCycleTypeD[];
}

/**
 * Request type for GetWashTrading
 */
export interface GetWashTradingRequestParams {
  // Filters results to the provided wallet identifier.
  // Supports both raw wallet addresses and internal wallet IDs.
  //
  // One of walletId or collectionId is required.
  walletId?: string;

  // Filters results to the provided NFT collection id.
  //
  // One of walletId or collectionId is required.
  collectionId?: string;

  // Number of results to retrieve. Returns up to this number of results.
  pageSize?: number;
}

export interface WashTradeActor {
  walletId: string;
  twitterName: string;
  twitterHandle: string;
}

export enum WashTradingActions {
  sold_nft = "sold_nft",
  sent_funds = "sent_funds",
}

export enum WashTradeUIAction {
  SOLD_NFT_TO = "Sold NFT to",
  SENT_FUNDS_TO = "Sent funds to",
}

export interface WashTradingResponse {
  washtradingAllResults: WashTrading[];
}

export interface WashTrading {
  utcTime1: ConvertableUTC;
  action1: WashTradeUIAction;
  price1SOL: number;
  sender1: WashTradeActor;
  receiver1: WashTradeActor;

  utcTime2: ConvertableUTC;
  action2: WashTradeUIAction;
  price2SOL: number;
  sender2: WashTradeActor;
  receiver2: WashTradeActor;
}

/**
 * Response type for GetWashTrading
 */
export interface GetWashTradingResponse {
  // type B cycle transactions.
  typeBResults: SuspectedCycleTypeB[];

  // type C cycle transactions.
  typeCResults: SuspectedCycleTypeC[];

  // type D cycle transactions.
  typeDResults: SuspectedCycleTypeD[];
}

/**
 * Request params for NftCollectionNetProfit
 */
export interface NftCollectionNetProfitRequestParams {
  // Filters results for the collection in question.
  collectionId?: string;

  // The time period for results.
  timeRangeFilter: TimeRanges;
}

/**
 * Response for NftCollectionNetProfit
 */
export interface NftCollectionNetProfitResponse {
  // Net profit time series.
  results: BasicAggregationResult<string, number>;
}

/**
 * Request params for NftsWeeklyOverview and NftsDailyOverview
 */
export interface NftsOverviewRequestParams {
  // The time period for results.
  timeRangeFilter: TimeRanges;
}

/**
 * Response for PortfolioDataByMints
 */
export interface PortfolioDataByMintsResponse {
  // Mint correlated to various NFT statistics
  results: PortfolioDataByMintsQueryResult[];
}

/**
 * Response for PortfolioTokenPriceByMints
 */
export interface PortfolioTokenPriceByMintsResponse {
  // Mint correlated to avg usd price from jupiter txns
  results: PortfolioTokenPricesQueryResult[];
}

export type NftNotableTransaction = {
  buyer: WalletMeta;
  seller: WalletMeta;
  collectionId: string;
  name: string;
  mint: string;
  transactionTime: string;
  price: string;
  collectionImageUri: string;
  collectionSlug: string;
  maskedTransactionId: string;
};

export type NftNotableTransactionActor = {
  primaryActor: WalletMeta;
  saleType: SaleType;
  transactionTime: string;
  price: string;
  collectionName: string;
  collectionSlug: string;
  maskedTransactionId: string;
};

export const enum SaleType {
  BUYER = "BUYER",
  SELLER = "SELLER",
  NOTYPE = "NOTYPE",
}

export const enum BidType {
  CREATE = "CREATE",
  CANCEL = "CANCEL",
}
export const enum ListingType {
  CREATE = "CREATE",
  CANCEL = "CANCEL",
}

export type Listing = {
  nft: Nft;
  market: string;
  priceSol: number;
  type: ListingType;
  time: string;
  maskedTransactionId: string;
};

export type Bid = {
  nft: Nft;
  market: string;
  priceSol: number;
  type: BidType;
  time: string;
};

export type NftNotableListing = {
  actor: WalletMeta;
  listing: Listing;
};

export type NftNotableBid = {
  actor: WalletMeta;
  listing: Bid;
};

export type TwitterMeta = {
  id: number;
  idStr: string; // use whenever possible, as screen_name is subject to change
  name: string;
  screenName: string;
  location: string | null;
  url: string | null;
  description: string | null;
  protected: boolean;
  verified: boolean;
  followersCount: number;
  friendsCount: number;
  listedCount: number;
  favouritesCount: number;
  statusesCount: number; // # of tweets, incl. retweets
  createdAt: string; // UTC datetime user account was created
  profileBannerUrl: string;
  profileImage: string; // The publically displayable image URL for the image posted.
  defaultProfile: boolean; // true = user didn't alter theme or background
  defaultProfileImage: boolean;
  withheldInCountries: string[]; // presence = list of uppercase 2 letter country codes content is withheld from
  withheldScope: string;
};

export type NftFeedItem = {
  listing?: NftNotableListing;
  transaction?: NftNotableTransaction;
  bid?: NftNotableBid;
  time?: string;

  // Unique identifier for an activity which is more specific than txn id.
  // E.g. may distinguish between the activity of individual actors within the same txn.
  activityId: string;
};

export type NftFeedTransactionItem = {
  listing?: NftNotableListing;
  transaction?: NftNotableTransactionActor;
  bid?: NftNotableBid;
  time?: string;

  // Unique identifier for an activity which is more specific than txn id.
  // E.g. may distinguish between the activity of individual actors within the same txn.
  activityId: string;
};

/**
 * Request params for NftNotableTransactions
 */
export interface NftNotableTransactionsRequestParams {
  // Filters results for the collection in question.
  collectionId: string;

  // The time period for results.
  timeRangeFilter: TimeRanges;

  // Number of results
  pageSize?: number;
}

/**
 * Response for NftNotableTransactions.
 */
export interface NftNotableTransactionsResponse {
  // The transactions returned.
  results: NftNotableTransaction[];
}

/**
 * Request params for NftNotableListings
 */
export interface NftNotableListingsRequestParams {
  // Filters results for the collection in question.
  // UNIMPLEMENTED
  collectionId?: string;

  // The time period for results.
  timeRangeFilter: TimeRanges;

  // Number of results
  pageSize?: number;

  // Results to skip
  offset?: number;
}

/**
 * Request params for NftNotableBids
 */
export interface NftNotableBidsRequestParams {
  // Filters results for the collection in question.
  // UNIMPLEMENTED
  collectionId?: string;

  // The time period for results.
  timeRangeFilter: TimeRanges;

  // Number of results
  pageSize?: number;

  // Pagination token
  paginationToken?: string;
}

/**
 * Response for NftNotableListings.
 */
export interface NftNotableListingsResponse {
  // The transactions returned.
  results: NftNotableListing[];
}

/**
 * Response for NftNotableBids.
 */
export interface NftNotableBidsResponse {
  // The transactions returned.
  results: NftNotableBid[];
}

/**
 * Request params for NftFeed
 */
export interface NftFeedRequestParams {
  // Filters results for the collection in question.
  collectionId?: string;

  // Number of results
  pageSize: number;

  // Results to skip
  offset: number;
}

/**
 * Response for NftFeed
 */
export interface NftFeedResponse {
  items: NftFeedItem[];
}

/**
 * Request params for NftFloorPriceCandles
 */
export interface NftFloorPriceCandlesRequestParams {
  // The NFT collection to filter for
  collectionId: string;
  // The start of the time range in seconds since the epoch
  startSeconds: number;
  // The end of the time range in seconds since the epoch
  endSeconds: number;
  // The number of bars the client wants, which should take precedence over the
  // time range if provided
  countRequested?: number;
  // The granularity of the time interval for the bars.
  granularity: Granularity;
}

/**
 * Response for NftFloorPriceCandles
 */
export interface NftFloorPriceCandlesResponse {
  // The candles
  candles: Candle[];
}

export interface NftListingCountOverTimeRequestParams {
  // The NFT collection to filter for
  collectionId: string;
  // The start of the time range in seconds since the epoch
  startSeconds: number;
  // The end of the time range in seconds since the epoch
  endSeconds: number;
  // The number of bars the client wants, which should take precedence over the
  // time range if provided
  countRequested?: number;
  // The granularity of the time interval for the bars.
  granularity: Granularity;
}

export interface SmartMinters {
  // List of distinct collection names displayed per row
  collectionName: string;
  // id of current collection
  collectionId: string;
  // slug of current collection
  collectionSlug: string;
  // Number of wallets that minted this collection (one wallet can mint multiple nfts in a collection)
  numMintedByWallets: number;
  // Number of NFTs minted by Smart Minters
  totalMintedBySmartMinters: number;
  // Number of NFTs from collection, i.e: total nft supply
  totalMinted: number;
  // minted price usd
  mintPriceSol: number;
  // totalMinted * current nft price in usd
  mintVolumeSol: number;
  // distance from last minted time and current time in UNIX
  lastMintTime: string;
  // total minted by smart minters / total minted (supply) = smartMintersByTotalMinted
  smartMintersByTotalMinted: number;
  // most recent floor price using redis candles
  floorPrice: number;
}

export interface SmartMinterResponse {
  smartMinters: SmartMinters[];
}

export interface SmartMinterRequestParams {
  timeRangeFilter: LeaderboardTimeRanges;
  pageSize?: number;
  offset?: number;
  sort?: SmartMinterSort;
  sortDirection?: SortDirection;
  nameFilter?: string;
}

export enum NftTopWalletSort {
  WALLET_ADDRESS = "WALLET_ADDRESS",
  RANKED_VALUE = "RANKED_VALUE",
  TOTAL_NFT_SOL = "TOTAL_NFT_SOL",
  WASH_TRADING_SCORE = "WASH_TRADING_SCORE",
}

export const salesPriceMean = "salesPriceMean";
export const salesPriceMedian = "salesPriceMedian";
export const activeOwnersCumulativeCount = "activeOwnersCumulativeCount";
export const activeOwnersCount = "activeOwnersCount";
export const transfersCount = "transfersCount";
export const buyersCount = "buyersCount";
export const sellersCount = "sellersCount";
