import { AxiosRequestConfig } from "axios";
import { SocialService } from "model/aggregations/social/service";
import {
  ListSocialsResponse,
  SocialActivitiesResponse,
} from "model/aggregations/social/types";
import enhancedAxios from "./requestUtil";

export class SocialClient implements SocialService {
  listSocials(args?: any): Promise<ListSocialsResponse> {
    return this.get<ListSocialsResponse>("listSocials", args);
  }

  listSocialActivities(args?: any): Promise<SocialActivitiesResponse> {
    return this.get<SocialActivitiesResponse>("listSocialActivities", args);
  }

  async get<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.get(
      `/api/aggregations/social/${route}`,
      args,
    );
    return response.data;
  }
}
