import styled from "styled-components";
import { useEffect, useState } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { PUBLIC_KEY_LOCALSTORAGE_KEY } from "pages/_app";

const WalletSignInContainer = styled.div`
  > * {
    font-family: inherit;
    color: inherit;
    background-color: inherit;
  }

  z-index: 100;

  .wallet-adapter-button-start-icon {
    display: none !important;
  }

  .wallet-adapter-button-trigger {
    background-color: transparent;
    border-radius: 50px;
    border: solid 1px var(--tw-shadow-colored);
    border: solid 1px #1e78ff;
    margin-left: 24px;
    height: 36px;
    padding: 0px 24px;
    font-weight: normal;
    font-size: 14px;
    white-space: nowrap;
    color: var(--hmio-white);
    text-align: center;

    :hover {
      background-color: var(--nav-wallet-200);
    }

    :active {
      background-color: var(--nav-wallet-100);
      padding: none;
    }
  }
  .connected-button {
    border: 2px solid var(--nav-wallet-200);
    border-radius: 50px;
    margin-left: 24px;
    height: 36px;
    padding: 0px 24px;
    font-weight: normal;
    font-size: 14px;
    white-space: nowrap;
    color: var(--hmio-white);
    text-align: center;
  }
`;

export const WalletSignInHeader = () => {
  const { connected, publicKey } = useWallet();

  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (connected && publicKey != null) {
      const wallet_id = publicKey.toString();
      localStorage.setItem(PUBLIC_KEY_LOCALSTORAGE_KEY, wallet_id);
      sessionStorage.setItem(PUBLIC_KEY_LOCALSTORAGE_KEY, wallet_id);
    } else {
      localStorage.setItem(PUBLIC_KEY_LOCALSTORAGE_KEY, "");
      sessionStorage.setItem(PUBLIC_KEY_LOCALSTORAGE_KEY, "");
    }
  }, [publicKey, connected]);

  // Don't render wallet button modal if we're not on the client, because it prevent hydration errors
  if (!mounted) return null;

  return (
    <WalletSignInContainer>
      {connected ? (
        <WalletMultiButton className="connected-button" />
      ) : (
        <WalletMultiButton>Connect wallet</WalletMultiButton>
      )}
    </WalletSignInContainer>
  );
};
