import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  LastNrtBlockIdsRequestParams,
  LastNrtBlockIdsResponse,
} from "model/aggregations/nrt/types";
import { DailyActivityRequest } from "model/aggregations/solana/requests";
import {
  AggregationsService,
  BasicAggregationResult,
  ConfiguredAggregationEndpoints,
  SeriesY,
  KeyStatsResult,
  DefiPrograms,
  ContractSummaryResult,
  TokenPriceSummary,
  ConfiguredAggregationPostEndpoints,
  GetCandlesticksRequestParams,
  GetCandlesicksResponse,
} from "../model/aggregations";
import {
  ProgramOverlapResponse,
  ProgramVolumeNotableTransactionsRequestParams,
  ProgramVolumeNotableTransactionsResponse,
  ProgramActiveUsersOverTimeRequestParams,
  ProgramNewUserOverTimeRequestParams,
  DefiProtocolCommunityTokenWealthResponse,
  DefiProtocolByTopTokensVolumeRequestParams,
  DefiProtocolByTopTokensVolumeResponse,
  DefiProtocolHr24VolumeByWalletSizeRequestParams,
  DefiProtocolDailyActiveUsersByWalletSizeRequestParams,
  ProtocolFeedByVolumeRequestParams,
  ProtocolFeedByVolumeResponse,
  DefiMarketActivityResponse,
} from "../model/aggregations/defiPrograms";
import {
  DefiTokenActiveUserOverTimeRequestParams,
  DefiTokenNewUserOverTimeRequestParams,
  DefiTokenCommunityTokenWealthResponse,
  DefiTokenByTopProtocolsVolumeRequestParams,
  DefiTokenByTopProtocolsVolumeResponse,
  DefiTokenDailyActiveUsersByWalletSizeRequestParams,
  DefiTokenDailyVolumeByWalletSizeRequestParams,
  TokenPriceCandlesRequestParams,
  TokenPriceCandlesResponse,
} from "../model/aggregations/defiTokens";
import {
  NftKeyStats,
  TopHolderResponse,
  NFTargs,
  NFTOverlapResponse,
  NftOwnershipAllTimeResponse,
  NftPriceHistoryRequest,
  NftPriceHistoryResponse,
  NftSalesVolumeRequest,
  NftSalesVolumeResponse,
  TokenHoldingStatsResp,
  NftHoldersByPeriodResponse,
  HoldersBySizeResponse,
  NftTransactingWalletsRequestParams,
  NftTransactingWalletsResponse,
  NftDistinctOwnersResponse,
  NftFloorPriceRequestParams,
  NftFloorPriceResponse,
  NftCollectionNetProfitResponse,
  NftMintsRequestParams,
  NftMintsResponse,
  NftTransactionsByProgramRequestParams,
  NftTransactionsByProgramResponse,
  NftVolumeByWashRequestParams,
  NftVolumeByWashResponse,
  MintsByCostBasisRequestParams,
  MintsByCostBasisResponse,
  GetWashTradingTypeDRequestParams,
  GetWashTradingTypeDResponse,
  PortfolioDataByMintsResponse,
  PortfolioTokenPriceByMintsResponse,
  NftsOverviewRequestParams,
  NftNotableTransactionsRequestParams,
  NftNotableTransactionsResponse,
  NftNotableBidsRequestParams,
  NftNotableBidsResponse,
  NftFeedRequestParams,
  NftFeedResponse,
  NftFloorPriceCandlesRequestParams,
  NftFloorPriceCandlesResponse,
  MarketActivityResponse,
  MarketActivityRequest,
  WashTradingResponse,
  WashTradingRequestParams,
} from "../model/aggregations/nfts";
import {
  TokenNotableTransactionsRequestParams,
  TokenPriceNotableTransactionsResponse,
  TokenFeedNotableTransactionsRequestParams,
  TokenFeedNotableTransactionsResponse,
  ListTokensResponse,
} from "../model/aggregations/tokens";
import {
  ListTopWalletsRequestParams,
  ListTopWalletsResponse,
  TopWalletsByHr24VolumeRequestParams,
  ListTopWalletsByHr24VolumeResponse,
  TopWalletsByHoldingsRequestParams,
  ListTopWalletsByHoldingsResponse,
} from "../model/aggregations/wallets";
import enhancedAxios from "./requestUtil";

function mapAggregationResponse<T>(input: AxiosResponse): T {
  const rawData = input.data;
  return {
    ...rawData,
    asOf: new Date(rawData.asOf),
  };
}

/**
 * This is ported over from Chalice.
 * The URLs are mostly wrong, but you can update them to match the paths used in ChaliceNext.
 */
export class AggregationsClient implements AggregationsService {
  averageAmountTransferred(
    args?: any,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get(
      ConfiguredAggregationEndpoints.AVERAGE_AMOUNT_TRANSFERRED,
      args,
    );
  }

  uniqueBuyers(args?: any): Promise<BasicAggregationResult<string, number>> {
    return this.get<BasicAggregationResult<string, number>>(
      ConfiguredAggregationEndpoints.UNIQUE_BUYERS,
      args,
    );
  }

  uniqueSellers(args?: any): Promise<BasicAggregationResult<string, number>> {
    return this.get<BasicAggregationResult<string, number>>(
      ConfiguredAggregationEndpoints.UNIQUE_SELLERS,
      args,
    );
  }

  keyStats(args?: any): Promise<KeyStatsResult> {
    return this.get<KeyStatsResult>(
      ConfiguredAggregationEndpoints.KEY_STATS,
      args,
    );
  }

  averageWalletBalanceBuyers(
    args?: any,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      ConfiguredAggregationEndpoints.AVERAGE_WALLET_BALANCE_BUYERS,
      args,
    );
  }

  averageWalletBalanceSellers(
    args?: any,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      ConfiguredAggregationEndpoints.AVERAGE_WALLET_BALANCE_SELLERS,
      args,
    );
  }

  dailyFirstSigners(
    args?: any,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      ConfiguredAggregationEndpoints.DAILY_FIRST_SIGNERS,
      args,
    );
  }

  dailySolanaActivity(args: {
    params: DailyActivityRequest;
  }): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      ConfiguredAggregationEndpoints.SOLANA_DAILY_ACTIVITY,
      args,
    );
  }

  totalValueMovedOnChain(
    args?: any,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      ConfiguredAggregationEndpoints.TOTAL_VALUE_MOVED_ON_CHAIN,
      args,
    );
  }

  dailyActiveUsers(
    args?: any,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      ConfiguredAggregationEndpoints.DAILY_ACTIVE_USERS,
      args,
    );
  }

  ecosystem(
    args?: any,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get(ConfiguredAggregationEndpoints.ECOSYSTEM, args);
  }

  volume(args?: any): Promise<BasicAggregationResult<number, number>> {
    return this.get(ConfiguredAggregationEndpoints.VOLUME, args);
  }

  price(
    args?: any,
  ): Promise<BasicAggregationResult<number, TokenPriceSummary>> {
    return this.get(ConfiguredAggregationEndpoints.PRICE, args);
  }

  nftFloorPrice(args?: {
    params: NftFloorPriceRequestParams;
  }): Promise<NftFloorPriceResponse> {
    return this.get<NftFloorPriceResponse>(
      ConfiguredAggregationEndpoints.NFT_FLOOR_PRICE,
      args,
    );
  }

  topWallets(args?: {
    params: ListTopWalletsRequestParams;
  }): Promise<ListTopWalletsResponse> {
    return this.get("topWallets", args);
  }

  washTrading(args?: {
    params: WashTradingRequestParams;
  }): Promise<WashTradingResponse> {
    return this.get("nfts/washTrading", args);
  }

  washTradingTypeD(args?: {
    params: GetWashTradingTypeDRequestParams;
  }): Promise<GetWashTradingTypeDResponse> {
    return this.get(ConfiguredAggregationEndpoints.WASH_TRADING_TYPE_D, args);
  }

  portfolioDataByMints(args?: any): Promise<PortfolioDataByMintsResponse> {
    return this.post("portfolio/portfolioDataByMints", args);
  }

  portfolioTokenPriceByMints(
    args?: any,
  ): Promise<PortfolioTokenPriceByMintsResponse> {
    return this.post(
      ConfiguredAggregationPostEndpoints.PORTFOLIO_TOKEN_PRICE_BY_MINTS,
      args,
    );
  }

  nftCollectionNotableTransactions(args: {
    params: NftNotableTransactionsRequestParams;
  }): Promise<NftNotableTransactionsResponse> {
    return this.get<NftNotableTransactionsResponse>(
      ConfiguredAggregationEndpoints.NFT_COLLECTION_NOTABLE_TRANSACTIONS,
      args,
    );
  }

  defiProgramByActiveUserCount(args?: {
    params: ProgramActiveUsersOverTimeRequestParams;
  }): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      ConfiguredAggregationEndpoints.DEFI_PROGRAM_BY_ACTIVE_USER_COUNT,
      args,
    );
  }

  defiTokenByCommunityTokenWealth(
    args?: any,
  ): Promise<DefiTokenCommunityTokenWealthResponse> {
    return this.get(
      ConfiguredAggregationEndpoints.DEFI_TOKEN_BY_COMMUNITY_TOKEN_WEALTH,
      args,
    );
  }

  defiTokenByTopProtocolsVolume(args?: {
    params: DefiTokenByTopProtocolsVolumeRequestParams;
  }): Promise<DefiTokenByTopProtocolsVolumeResponse> {
    return this.get<DefiTokenByTopProtocolsVolumeResponse>(
      ConfiguredAggregationEndpoints.DEFI_TOKEN_BY_TOP_PROTOCOLS_VOLUME,
      args,
    );
  }

  tokenPriceCandles(args: {
    params: TokenPriceCandlesRequestParams;
  }): Promise<TokenPriceCandlesResponse> {
    return this.get<TokenPriceCandlesResponse>(
      "tokens/tokenPriceCandles",
      args,
    );
  }

  async candlesticks(args: {
    params: GetCandlesticksRequestParams;
  }): Promise<GetCandlesicksResponse> {
    // TODO: fix this.. there should be no logic here.
    const resp = await enhancedAxios.get<GetCandlesicksResponse>(
      `/api/aggregations/candlesticks`,
      args,
    );

    return mapAggregationResponse<GetCandlesicksResponse>(resp);
  }

  async nftMarketActivity(args: {
    params: MarketActivityRequest;
  }): Promise<MarketActivityResponse> {
    return this.get<MarketActivityResponse>(
      ConfiguredAggregationEndpoints.NFT_MARKET_ACTIVITY,
      args,
    );
  }

  async defiMarketActivity(
    args: any,
  ): Promise<DefiPrograms.DefiMarketActivityResponse> {
    return this.get<DefiMarketActivityResponse>(
      ConfiguredAggregationEndpoints.DEFI_MARKET_ACTIVITY,
      args,
    );
  }

  async lastNrtBlockIds(args: {
    params: LastNrtBlockIdsRequestParams;
  }): Promise<LastNrtBlockIdsResponse> {
    return this.get<LastNrtBlockIdsResponse>(
      ConfiguredAggregationEndpoints.LAST_NRT_BLOCK_IDS,
      args,
    );
  }

  async get<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const resp = await enhancedAxios.get(`/api/aggregations/${route}`, args);
    return mapAggregationResponse<T>(resp);
  }

  async post<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const resp = await enhancedAxios.post(`/api/aggregations/${route}`, args);
    return mapAggregationResponse<T>(resp);
  }
}
