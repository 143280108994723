import { AxiosRequestConfig } from "axios";
import { DefiPrograms } from "../model/aggregations";
import enhancedAxios from "./requestUtil";

export class DefiProgramsClient implements DefiPrograms.DefiProgramsService {
  getDefiProgram(
    args?: DefiPrograms.GetDefiProgramArgs,
  ): Promise<DefiPrograms.GetDefiProgramResponse> {
    return this.get<DefiPrograms.GetDefiProgramResponse>(
      "getDefiProgram",
      args,
    );
  }

  getDefiProgramStats(
    args: DefiPrograms.GetDefiProgramStatsArgs,
  ): Promise<DefiPrograms.GetDefiProgramStatsResponse> {
    return this.get<DefiPrograms.GetDefiProgramStatsResponse>(
      "getDefiProgramStats",
      args,
    );
  }

  getDefiPrograms(
    args?: DefiPrograms.GetDefiProgramsArgs,
  ): Promise<DefiPrograms.GetDefiProgramsResponse> {
    return this.get<DefiPrograms.GetDefiProgramsResponse>(
      "getDefiPrograms",
      args,
    );
  }

  listPrograms(
    args: DefiPrograms.ListDefiProgramsArgs,
  ): Promise<DefiPrograms.GetDefiProgramsResponse> {
    return this.get<DefiPrograms.GetDefiProgramsResponse>("listDefiPrograms", {
      params: {
        ...args,
      },
    });
  }

  getDefiProgramsForSearch(
    args: DefiPrograms.GetDefiProgramsArgs,
  ): Promise<DefiPrograms.GetDefiProgramsForSearchResponse> {
    return this.get<DefiPrograms.GetDefiProgramsForSearchResponse>(
      "getDefiProgramsForSearch",
      args,
    );
  }

  async get<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.get(`/api/programs/${route}`, args);
    return response.data;
  }
}
