import type {
  BasicAggregationResult,
  SeriesY,
  TimeRanges,
} from "model/aggregations";
import type {
  NftKeyStats,
  TopHolderResponse,
  HoldersBySizeResponse,
  NFTOverlapResponse,
  NftOwnershipAllTimeResponse,
  NftPriceHistoryResponse,
  NftSalesVolumeResponse,
  TokenHoldingStatsResp,
  NftHoldersByPeriodResponse,
  NftTransactingWalletsResponse,
  NftTransactionsByProgramResponse,
  NftDistinctOwnersResponse,
  NftMintsResponse,
  NftFloorPriceResponse,
  NftVolumeByWashResponse,
  MintsByCostBasisResponse,
  NftCollectionNetProfitResponse,
  NftNotableTransactionsResponse,
  NftNotableBidsResponse,
  NftFeedResponse,
  NftFloorPriceCandlesResponse,
  MarketActivityResponse,
  GetWashTradingResponse,
  NftOwnershipAllTimeRequestParams,
  NftPriceHistoryRequest,
  NftSalesVolumeRequest,
  NftHoldersByPeriodRequestParams,
  NftTransactingWalletsRequestParams,
  NftDistinctOwnersRequestParams,
  NftFloorPriceCandlesRequestParams,
  NftNotableTransactionsRequestParams,
  WashTradingRequestParams,
  WashTradingResponse,
} from "model/aggregations/nfts";
import type { NftAggregationsService } from "model/aggregations/nfts/service";
import type {
  ListTopWalletsResponse,
  WalletActorRole,
} from "model/aggregations/wallets";
import enhancedAxios from "./requestUtil";

export default class NftAggregationsClient implements NftAggregationsService {
  private readonly baseUrl = "/api/aggregations/nfts";

  private async get<TRes>(route: string, params?: any): Promise<TRes> {
    const response = await enhancedAxios.get(`${this.baseUrl}/${route}`, {
      params: { ...params },
    });

    return response.data as TRes;
  }

  public async nftKeyStats(params: { id: string }): Promise<NftKeyStats> {
    return this.get("nftKeyStats", params);
  }

  /** @deprecated -- do we use this ?? */
  public async nftTopHolders(params: {
    id: string;
  }): Promise<TopHolderResponse> {
    return this.get("nftTopHolders", params);
  }

  public async nftHoldersBySize(params: {
    id: string;
  }): Promise<HoldersBySizeResponse> {
    return this.get("nftHoldersBySize", params);
  }

  public async nftOverlap(params: { id: string }): Promise<NFTOverlapResponse> {
    return this.get("nftOverlap", params);
  }

  public async nftOwnershipAllTime(
    params: NftOwnershipAllTimeRequestParams,
  ): Promise<NftOwnershipAllTimeResponse> {
    return this.get("nftOwnershipAllTime", params);
  }

  public async nftPriceHistory(
    params: NftPriceHistoryRequest,
  ): Promise<NftPriceHistoryResponse> {
    return this.get("nftPriceHistory", params);
  }

  public async nftSalesVolume(
    params: NftSalesVolumeRequest,
  ): Promise<NftSalesVolumeResponse> {
    return this.get("nftSalesVolume", params);
  }

  public async nftTokenStats(params: {
    id: string;
  }): Promise<TokenHoldingStatsResp> {
    return this.get("nftTokenStats", params);
  }

  public async nftHoldersByPeriod(
    params: NftHoldersByPeriodRequestParams,
  ): Promise<NftHoldersByPeriodResponse> {
    return this.get("nftHoldersByPeriod", params);
  }

  public async nftTransactingWallets(
    params: NftTransactingWalletsRequestParams,
  ): Promise<NftTransactingWalletsResponse> {
    return this.get("nftTransactingWallets", params);
  }

  public async nftTransactionsByProgram(params: {
    timeRangeFilter: string;
  }): Promise<NftTransactionsByProgramResponse> {
    return this.get("nftTransactionsByProgram", params);
  }

  public async nftDistinctOwners(
    params: NftDistinctOwnersRequestParams,
  ): Promise<NftDistinctOwnersResponse> {
    return this.get("nftDistinctOwners", params);
  }

  public async nftMints(): Promise<NftMintsResponse> {
    return this.get("nftMints");
  }

  public async nftFloorPrice(params: {
    collectionId: string;
    timeRangeFilter: string;
  }): Promise<NftFloorPriceResponse> {
    return this.get("nftFloorPrice", params);
  }

  /** @deprecated -- do we use this ??  */
  public async nftVolumeByWashQuality(params: {
    collectionId: string;
    timeRangeFilter: string;
  }): Promise<NftVolumeByWashResponse> {
    return this.get("nftVolumeByWashQuality", params);
  }

  /** @deprecated -- do we use this ?? */
  public async nftMintsByCostBasis(
    args?: any,
  ): Promise<MintsByCostBasisResponse> {
    throw new Error("Method not implemented.");
  }

  /** @deprecated -- do we use this ?? */
  public async nftCollectionNetProfit(
    args?: any,
  ): Promise<NftCollectionNetProfitResponse> {
    throw new Error("Method not implemented.");
  }

  public async nftsWeeklyOverview(params: {
    timeRangeFilter: string;
  }): Promise<BasicAggregationResult<string, SeriesY<number>>> {
    return this.get("nftsWeeklyOverview", params);
  }

  public async nftsDailyOverview(params: {
    timeRangeFilter: string;
  }): Promise<BasicAggregationResult<string, SeriesY<number>>> {
    return this.get("nftsDailyOverview", params);
  }

  public async nftCollectionNotableTransactions(
    params: NftNotableTransactionsRequestParams,
  ): Promise<NftNotableTransactionsResponse> {
    return this.get("nftCollectionNotableTransactions", params);
  }

  /** @deprecated -- do we use this ?? */
  public async nftCollectionNotableBids(
    args?: any,
  ): Promise<NftNotableBidsResponse> {
    throw new Error("Method not implemented.");
  }

  public async nftVolumeByMarket(params: {
    timeRangeFilter: string;
  }): Promise<NftTransactionsByProgramResponse> {
    return this.get("nftVolumeByMarket", params);
  }

  public async nftFeed(params: {
    collectionId: string;
    pageSize: number;
    offset: number;
    actorRole: WalletActorRole;
  }): Promise<NftFeedResponse> {
    return this.get("nftFeed", params);
  }

  public async nftFloorPriceCandles(
    params: NftFloorPriceCandlesRequestParams,
  ): Promise<NftFloorPriceCandlesResponse> {
    return this.get("nftFloorPriceCandles", params);
  }

  public async nftMarketActivity(params: {
    timeRange:
      | TimeRanges.MINUTE_60
      | TimeRanges.DAY
      | TimeRanges.WEEK
      | TimeRanges.MONTH;
  }): Promise<MarketActivityResponse> {
    return this.get("nftMarketActivity", params);
  }

  public async nftTopWallets(params: {
    rankingType: string;
    topLevel: string;
  }): Promise<ListTopWalletsResponse> {
    return this.get("nftTopWallets", params);
  }

  public async washTrading(
    params: WashTradingRequestParams,
  ): Promise<WashTradingResponse> {
    return this.get("washTrading", params);
  }
}
