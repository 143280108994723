import type { AxiosRequestConfig } from "axios";
import type { BasicAggregationResult } from "model/aggregations";
import type {
  GetNftResponse,
  GetNftSummaryResponse,
  ListNftResponse,
  NftFeedResponse,
  SmartMinterResponse,
} from "../model/aggregations/nfts";
import type { NftService } from "../model/aggregations/nfts/service";
import enhancedAxios from "./requestUtil";

export class NftClient implements NftService {
  listNfts(params: any): Promise<ListNftResponse> {
    return this.get<ListNftResponse>("listNfts", params);
  }

  getNft(params: any): Promise<GetNftResponse> {
    return this.get<GetNftResponse>("getNft", params);
  }

  getNftSummary(params: any): Promise<GetNftSummaryResponse> {
    return this.get<GetNftSummaryResponse>("getNftSummary", params);
  }

  nftFeed(params: any): Promise<NftFeedResponse> {
    return this.get<NftFeedResponse>("nftFeed", params);
  }

  nftListingCountOverTime(
    params: any,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      "nftListingCountOverTime",
      params,
    );
  }

  getSmartMinter(params?: any): Promise<SmartMinterResponse> {
    return this.get<SmartMinterResponse>("getSmartMinter", params);
  }

  async get<T>(route: string, params: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.get<T>(`/api/nfts/${route}`, params);
    return response.data;
  }
}
