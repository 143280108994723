import { Granularity } from "./nfts";

export * as DefiPrograms from "./defiPrograms";
export * as NFTS from "./nfts";
export * as Tokens from "./tokens";
export * as DefiTokens from "./defiTokens";
export * from "./service";

export interface BasicAggregationRow<X, Y> {
  x: X;
  y: Y;
}

export interface BasicAggregationResult<X, Y> {
  name: string;
  asOf: Date;
  xLabel: string;
  yLabel: string;
  yKeyOrder?: string[];
  data: BasicAggregationRow<X, Y>[];
}

export interface SeriesY<Y> {
  [key: string | number | symbol]: Y;
}

export interface Candle {
  lastBlockId?: number;
  // Epoch seconds as a string.
  time: string;
  high: number;
  low: number;
  open: number;
  close: number;
  // These are non-decimal-adjusted fractional units of whatever token in question.
  // This is done for consistency with the stream endpoint which does not do this adjustment.
  volume?: number;
}

export interface LeaderboardStat {
  // Latest price in USDC fractional units
  currPrice: number;
  // Price 1 interval ago in USDC fractional units
  prevPrice: number;
  // Change either positive or negative from the prev price to the currPrice, as (curr / prev) - 1
  // E.g. 0.35 means curr is 1.35x prev
  priceChangePercent: number;
  // Volume in this interval in fractional units
  currVolume: number;
  // Volume in the previous interval in fractional units
  prevVolume: number;
  // Change either positive or negative from the prevVolume to the currVolume, as (curr / prev) - 1
  // E.g. 0.35 means curr is 1.35x prev
  volumeChangePercent: number;
}

export interface CandleDict {
  [key: string]: Candle;
}
export interface StatsDict {
  [key: string]: LeaderboardStat;
}

/**
 * Request params for Candlesticks endpoint
 */
export interface GetCandlesticksRequestParams {
  assetType: AssetType;
  // The asset to filter for
  // For collections, this is the NFT collection id
  // For tokens, this is the token mint
  identifier: string;
  // The start of the time range in seconds since the epoch
  startSeconds: number;
  // The end of the time range in seconds since the epoch
  endSeconds: number;
  // The number of bars the client wants, which should take precedence over the
  // time range if provided
  countRequested?: number;
  // The granularity of the time interval for the bars.
  granularity: Granularity;
}

/**
 * Response type for Candlesticks endpoint
 */
export interface GetCandlesicksResponse {
  // The candlestick data.
  candlesticks: Candle[];
}

export enum ConfiguredAggregationEndpoints {
  AVERAGE_AMOUNT_TRANSFERRED = "average-amount-transferred",
  AVERAGE_WALLET_BALANCE_BUYERS = "average-wallet-balance-buyers",
  AVERAGE_WALLET_BALANCE_SELLERS = "average-wallet-balance-sellers",
  DAILY_ACTIVE_USERS = "daily-active-users",
  DAILY_FIRST_SIGNERS = "daily-first-signers",
  DEFI_MARKET_ACTIVITY = "programs/activity",
  DEFI_PROGRAM_BY_ACTIVE_USER_COUNT = "programs/defiProgramByActiveUserCount",
  DEFI_PROGRAM_BY_NEW_USER_COUNT = "programs/defiProgramByNewUserCount",
  DEFI_PROTOCOL_BY_COMMUNITY_TOKEN_WEALTH = "programs/defiCommunityTokenWealth",
  DEFI_PROTOCOL_BY_TOP_TOKENS_VOLUME = "programs/defiProtocolByTopTokensVolume",
  DEFI_PROTOCOL_DAILY_ACTIVE_USERS_BY_WALLET_SIZE_USD = "programs/defiProtocolDailyActiveUsersByWalletSizeUSD",
  DEFI_PROTOCOL_HR_24_VOLUME_BY_WALLET_SIZE_USD = "programs/defiProtocolHr24VolumeByWalletSizeUSD",
  DEFI_PROTOCOL_TOP_WALLETS_BY_HR_24_VOLUME = "programs/defiProtocolTopWalletsByHr24Volume",
  DEFI_TOKEN_BY_ACTIVE_USER_COUNT = "tokens/defiTokenByActiveUserCount",
  DEFI_TOKEN_BY_COMMUNITY_TOKEN_WEALTH = "tokens/defiTokenByCommunityTokenWealth",
  DEFI_TOKEN_BY_NEW_USER_COUNT = "tokens/defiTokenByNewUserCount",
  DEFI_TOKEN_BY_TOP_PROTOCOLS_VOLUME = "tokens/defiTokenByTopProtocolsVolume",
  DEFI_TOKEN_DAILY_ACTIVE_USERS_BY_WALLET_SIZE_USD = "tokens/defiTokenDailyActiveUsersByWalletSizeUSD",
  DEFI_TOKEN_DAILY_VOLUME_BY_WALLET_SIZE_USD = "tokens/defiTokenDailyVolumeByWalletSizeUSD",
  DEFI_TOKEN_TOP_WALLETS_BY_HOLDINGS = "tokens/defiTokenTopWalletsByHoldings",
  ECOSYSTEM = "tokens/ecosystem",
  GET_TOKENS = "get-tokens",
  HOT_CONTRACTS = "hotContracts",
  KEY_STATS = "nfts/keyStats",
  LAST_NRT_BLOCK_IDS = "nrt/lastNrtBlockIds",
  NET_NEW_BUYERS = "net-new-buyers",
  NFT = "nft",
  NFT_COLLECTION_NET_PROFIT = "nfts/nftCollectionNetProfit",
  NFT_COLLECTION_NOTABLE_TRANSACTIONS = "nfts/nftCollectionNotableTransactions",
  NFT_DAILY_OVERVIEW = "nfts/nftsDailyOverview",
  NFT_DISTINCT_OWNERS = "nfts/nftDistinctOwners",
  NFT_FEED = "nfts/nftFeed",
  NFT_FLOOR_PRICE = "nfts/nftFloorPrice",
  NFT_FLOOR_PRICE_CANDLES = "nfts/nftFloorPriceCandles",
  NFT_HOLDERS_BY_PERIOD = "nfts/nftHoldersByPeriod",
  NFT_HOLDERS_BY_SIZE = "nfts/nftHoldersBySize",
  NFT_KEY_STATS = "nfts/nftKeyStats",
  NFT_LISTING_COUNT_OVER_TIME = "nft-listing-count-over-time",
  NFT_MARKET_ACTIVITY = "nfts/marketActivity",
  NFT_MINTS = "nfts/nftMints",
  NFT_MINTS_BY_COST_BASIS = "nft-mints-by-cost-basis",
  NFT_NOTABLE_BIDS = "nft-notable-bids",
  NFT_OVERLAP = "nfts/nftOverlap",
  NFT_OWNERSHIP = "nfts/nftOwnershipAllTime",
  NFT_PRICE = "nfts/nftPriceHistory",
  NFT_SALES_VOLUME = "nfts/nftSalesVolume",
  NFT_SUMMARY = "nft-summary",
  NFT_TOP_HOLDERS = "nft-top-holders",
  NFT_TOP_TOKEN_STATS = "nfts/nftTokenStats",
  NFT_TRANSACTING_WALLETS = "nfts/nftTransactingWallets",
  NFT_TRANSACTIONS_BY_PROGRAM = "nfts/nftTransactionsByProgram",
  NFT_VOLUME_BY_MARKET = "nfts/nftVolumeByMarket",
  NFT_VOLUME_BY_WASH_QUALITY = "nft-volume-by-wash-quality",
  NFT_WEEKLY_OVERVIEW = "nfts/nftsWeeklyOverview",
  NFTS = "nfts",
  PRICE = "tokens/price",
  PROGRAM_OVERLAP = "programs/programOverlap",
  PROTOCOL_FEED_VOLUME_NOTABLE_TRANSACTIONS = "programs/programFeedNotableTransactions",
  PROTOCOL_VOLUME_NOTABLE_TRANSACTIONS = "protocolVolumeNotableTransactions",
  SOLANA_DAILY_ACTIVITY = "solana/dailyActivity",
  TOKEN_FEED_NOTABLE_TRANSACTIONS = "tokens/tokenFeedNotableTransactions",
  TOKEN_PRICE_CANDLES = "token-price-candles",
  TOKEN_PRICE_NOTABLE_TRANSACTIONS = "token-price-notable-transactions",
  TOKENS = "tokens",
  TOP_WALLETS = "top-wallets",
  TOTAL_VALUE_MOVED_ON_CHAIN = "total-value-moved-on-chain",
  UNIQUE_BUYERS = "unique-buyers",
  UNIQUE_SELLERS = "unique-sellers",
  VOLUME = "tokens/volume",
  WASH_TRADING = "nfts/washTrading",
  WASH_TRADING_TYPE_D = "wash-trading-type-d",
}

export enum ConfiguredAggregationPostEndpoints {
  NFT_PORTFOLIO_DATA_BY_MINTS = "nft-portfolio-data-by-mints",
  PORTFOLIO_TOKEN_PRICE_BY_MINTS = "portfolio-token-price-by-mints",
}

export enum TimeRanges {
  MINUTE_30 = "30-minute",
  MINUTE_60 = "60-minute",
  HOUR_6 = "6-hour",
  HOUR_12 = "12-hour",
  DAY = "1-day",
  WEEK = "1-week",
  MONTH = "1-month",
  TWO_MONTH = "2-month",
  ALL = "all",
}

export enum Granularities {
  THIRTY_MIN = "THIRTY_MIN",
  ONE_HOUR = "ONE_HOUR",
  HALF_DAY = "HALF_DAY",
  SIX_HOUR = "SIX_HOUR",
  ONE_DAY = "ONE_DAY",
  ONE_WEEK = "ONE_WEEK",
  ONE_MONTH = "ONE_MONTH",
}

/**
 * Sort BY: NftList | TokenList | ProgramList
 */
export enum Sorts {
  // HIGHLIGHTS + GLOBAL SEARCH
  ALPHABETICAL = "ALPHABETICAL",
  VOLUME_24H = "VOLUME_24H",
  SOCIAL_ACCOUNTS_BUYING_VOL = "SOCIAL_ACCOUNTS_BUYING_VOL",
  SMART_MONEY_NET_INFLOW = "SMART_MONEY_NET_INFLOW",
  SMART_MONEY_NET_OUTFLOW = "SMART_MONEY_NET_OUTFLOW",
  PRICE_USD_CHANGE = "PRICE_USD_CHANGE",
  SWAP_VOLUME_USD_1DAY = "SWAP_VOLUME_USD_1DAY",
  TOP_MOVERS_FLOOR_PRICE_CHANGE = "TOP_MOVERS_FLOOR_PRICE_CHANGE",

  // LEADERBOARD
  NAME = "NAME",
  FLOOR_PRICE = "FLOOR_PRICE",
  AVERAGE_PRICE = "AVERAGE_PRICE",
  VOLUME = "VOLUME",
  VOLUME_CHANGE = "VOLUME_CHANGE",
  FLOOR_PRICE_CHANGE = "FLOOR_PRICE_CHANGE",
  SMART_MONEY_NETFLOW = "SMART_MONEY_NETFLOW",
  WASH_TRADING_INDEX = "WASH_TRADING_INDEX",
  MARKET_CAP = "MARKET_CAP",
  WASHTRADING_INDEX = "WASHTRADING_INDEX",
  COUNT_BUYERS = "COUNT_BUYERS",
  COUNT_SELLERS = "COUNT_SELLERS",
}

/**
 * Each Enum represents a column
 * in the token leaderboard
 */
export enum TokenListSort {
  NAME = "NAME",
  VOLUME = "VOLUME",
  VOLUME_CHANGE = "VOLUME_CHANGE",
  PRICE_USD = "PRICE_USD",
  PRICE_CHANGE = "PRICE_CHANGE",
  USERS = "USERS",
  NEW_USERS = "NEW_USERS",
  BUYERS = "BUYERS",
  SELLERS = "SELLERS",

  // highlights
  SOCIAL_ACCOUNTS_BUYING_VOL = "SOCIAL_ACCOUNTS_BUYING_VOL",
  SMART_MONEY_NET_INFLOW = "SMART_MONEY_NET_INFLOW",
  SMART_MONEY_NET_OUTFLOW = "SMART_MONEY_NET_OUTFLOW",
  PRICE_USD_CHANGE = "PRICE_USD_CHANGE",
}

/**
 * NFT Headline SPECIFIC Time Ranges -> do not change unless you want to add / remove
 * granularities from Nft Headlines
 */
export enum HeadlineTimeRanges {
  DAY = "1D",
  WEEK = "1W",
}

export enum ShortenedTimeRanges {
  MIN30 = "30m",
  HOUR1 = "1H",
  HOUR6 = "6H",
  HOUR12 = "12H",
  DAY = "1D",
  WEEK = "1W",
  MONTH = "1M",
}

/**
 * leaderboad time ranges
 */
export enum LeaderboardTimeRanges {
  MINUTE_30 = "30 min",
  HOUR_1 = "1 hour",
  HOUR_6 = "6 hour",
  HOUR_12 = "12 hour",
  DAY = "1 day",
  WEEK = "1 week",
  MONTH = "1 month",
}

export enum EpochTime {
  ONE_MINUTE = 60,
  ONE_HOUR = 60 * 60,
  ONE_DAY = 60 * 60 * 24 * 1,
  ONE_WEEK = 60 * 60 * 24 * 7,
  ONE_MONTH = 60 * 60 * 24 * 30,
}

/** Basically key stats columns shown in Key Stats table */
export interface keyStats {
  date?: number;
  avg_wallet_content_buyer?: number;
  avg_wallet_content_seller?: number;
  buy_and_hold?: number;
  high_frequency_trader?: number;
  notable_buyers?: number;
  token_billionaires?: number;
  token_millionaires?: number;
  unique_buyers?: number;
}

/** Key stats results returned from Key Stats endpoint*/
export interface KeyStatsResult {
  data: {
    [key: string]: keyStats;
  };
}

export interface ContractSummaryResult {
  volume: number;
}

export interface TokenSummaryResult {
  token: string;
  name: string;
  symbol: string;
  slug: string;
  usd1dOnChainValue: number;
  onChainVolume1d: number;
  latestUsd1dExchangeVolume: number;
  latestUsd1dPriceChange: number;
  latestPriceUsd: number;
  latestUsdMarketCap: number;
  lastUpdatedAt: number;
}

/** A set of price related stats returned from the price endpoint. */
export interface TokenPriceSummary {
  address: string;
  symbol: string;
  name: string;
  averagePriceUsd: number;
}

export enum AssetType {
  NFT = "NFT",
  TOKEN = "TOKEN",
}

/**
 * Table names in table column of aggregation_freshtimes dynamo table
 */
export enum FreshtimeTableNames {
  VOLUME = "volume",
  ECOSYSTEM = "ecosystem",
  ECOSYSTEM_PERCENTAGE = "ecosystem_percentage",
  UNIQUE_BUYERS = "unique_receiver",
  UNIQUE_SELLERS = "unique_senders",
  AVERAGE_WALLET_CONTENT = "average_wallet_content",
  AVERAGE_WALLET_BALANCE_BUYERS = "average_wallet_content",
  AVERAGE_WALLET_BALANCE_SELLERS = "average_wallet_content",
}
