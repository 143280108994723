import { AxiosRequestConfig } from "axios";
import type {
  DefiProgramOverlapParams,
  DefiProtocolByTopTokensVolumeParams,
  DefiProtocolByTopTokensVolumeResponse,
  DefiProtocolCommunityTokenWealthParams,
  DefiProtocolCommunityTokenWealthResponse,
  DefiProtocolDailyActiveUsersByWalletSizeRequestParams,
  DefiProtocolHr24VolumeByWalletSizeRequestParams,
  ProgramNewUserOverTimeRequestParams,
  ProgramOverlapResponse,
  ProtocolFeedByVolumeRequestParams,
  ProtocolFeedByVolumeResponse,
} from "model/aggregations/defiPrograms";
import type { BasicAggregationResult, SeriesY } from "model/aggregations";
import type {
  DefiTokenDailyActiveUsersByWalletSizeUSDParams,
  DefiTokenDailyVolumeByWalletSizeUSDParams,
  DefiTokenEcosystemParams,
  DefiTokenPriceParams,
  DefiTokenVolumeParams,
} from "model/aggregations/defi/types";
import type {
  DefiTokenActiveUserOverTimeRequestParams,
  DefiTokenByTopProtocolsVolumeRequestParams,
  DefiTokenByTopProtocolsVolumeResponse,
  DefiTokenCommunityTokenWealthParams,
  DefiTokenCommunityTokenWealthResponse,
  DefiTokenNewUserOverTimeRequestParams,
} from "model/aggregations/defiTokens";
import type {
  TokenFeedNotableTransactionsRequestParams,
  TokenFeedNotableTransactionsResponse,
} from "model/aggregations/tokens";
import type {
  ListTopWalletsByHr24VolumeResponse,
  TopWalletsByHr24VolumeRequestParams,
  ListTopWalletsByHoldingsResponse,
  TopWalletsByHoldingsRequestParams,
} from "model/aggregations/wallets";
import enhancedAxios from "./requestUtil";
import { DefiAggregationsService } from "model/aggregations/defi/services";

export class DefiAggregationsClient implements DefiAggregationsService {
  // Note: since we typed params DefiAggregationsService
  // We should be good to type `params` as any since this
  // is a private method only called within this method
  // as an axios wrapper
  private async get<TRes>(route: string, params?: any): Promise<TRes> {
    const response = await enhancedAxios.get(
      `/api/aggregations/defi/${route}`,
      { params: { ...params } },
    );
    return response.data;
  }

  public async tokenFeedNotableTransactions(
    params: TokenFeedNotableTransactionsRequestParams,
  ): Promise<TokenFeedNotableTransactionsResponse> {
    return this.get<TokenFeedNotableTransactionsResponse>(
      "tokenFeedNotableTransactions",
      params,
    );
  }

  public async defiTokenByTopProtocolsVolume(
    params: DefiTokenByTopProtocolsVolumeRequestParams,
  ): Promise<DefiTokenByTopProtocolsVolumeResponse> {
    return this.get<DefiTokenByTopProtocolsVolumeResponse>(
      "defiTokenByTopProtocolsVolume",
      params,
    );
  }

  public async protocolFeedVolumeNotableTransactions(
    params: ProtocolFeedByVolumeRequestParams,
  ): Promise<ProtocolFeedByVolumeResponse> {
    return this.get<ProtocolFeedByVolumeResponse>(
      "protocolFeedVolumeNotableTransactions",
      params,
    );
  }

  public async defiTokenByCommunityTokenWealth(
    params: DefiTokenCommunityTokenWealthParams,
  ): Promise<DefiTokenCommunityTokenWealthResponse> {
    return this.get("defiTokenByCommunityTokenWealth", params);
  }

  public async defiTokenByActiveUserCount(
    params: DefiTokenActiveUserOverTimeRequestParams,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      "defiTokenByActiveUserCount",
      params,
    );
  }

  public async defiProtocolTopWalletsByHr24Volume(
    params: TopWalletsByHr24VolumeRequestParams,
  ): Promise<ListTopWalletsByHr24VolumeResponse> {
    return this.get<ListTopWalletsByHr24VolumeResponse>(
      "defiProtocolTopWalletsByHr24Volume",
      params,
    );
  }

  public async defiTokenTopWalletsByHoldings(
    params: TopWalletsByHoldingsRequestParams,
  ): Promise<ListTopWalletsByHoldingsResponse> {
    return this.get<ListTopWalletsByHoldingsResponse>(
      "defiTokenTopWalletsByHoldings",
      params,
    );
  }

  public async defiProgramByNewUserCount(
    params: ProgramNewUserOverTimeRequestParams,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      "defiProgramByNewUserCount",
      params,
    );
  }

  public async defiTokenByNewUserCount(
    params: DefiTokenNewUserOverTimeRequestParams,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>(
      "defiTokenByNewUserCount",
      params,
    );
  }

  public async defiProtocolDailyActiveUsersByWalletSizeUSD(
    params: DefiProtocolDailyActiveUsersByWalletSizeRequestParams,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      "defiProtocolDailyActiveUsersByWalletSizeUSD",
      params,
    );
  }

  public async ecosystem(
    params: DefiTokenEcosystemParams,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      "ecosystem",
      params,
    );
  }

  public async defiProtocolHr24VolumeByWalletSizeUSD(
    params: DefiProtocolHr24VolumeByWalletSizeRequestParams,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      "defiProtocolHr24VolumeByWalletSizeUSD",
      params,
    );
  }

  public async volume(
    params: DefiTokenVolumeParams,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get<BasicAggregationResult<number, number>>("volume", params);
  }

  public async defiTokenDailyActiveUsersByWalletSizeUSD(
    params: DefiTokenDailyActiveUsersByWalletSizeUSDParams,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      "defiTokenDailyActiveUsersByWalletSizeUSD",
      params,
    );
  }

  public async defiProtocolByCommunityTokenWealth(
    params: DefiProtocolCommunityTokenWealthParams,
  ): Promise<DefiProtocolCommunityTokenWealthResponse> {
    return this.get("defiProtocolByCommunityTokenWealth", params);
  }

  public async defiProtocolByTopTokensVolume(
    params: DefiProtocolByTopTokensVolumeParams,
  ): Promise<DefiProtocolByTopTokensVolumeResponse> {
    return this.get<DefiProtocolByTopTokensVolumeResponse>(
      "defiProtocolByTopTokensVolume",
      params,
    );
  }

  public async defiTokenDailyVolumeByWalletSizeUSD(
    params: DefiTokenDailyVolumeByWalletSizeUSDParams,
  ): Promise<BasicAggregationResult<number, SeriesY<number>>> {
    return this.get<BasicAggregationResult<number, SeriesY<number>>>(
      "defiTokenDailyVolumeByWalletSizeUSD",
      params,
    );
  }

  public async programOverlap(
    params: DefiProgramOverlapParams,
  ): Promise<ProgramOverlapResponse> {
    return this.get("programOverlap", params);
  }

  public async price(
    params: DefiTokenPriceParams,
  ): Promise<BasicAggregationResult<number, number>> {
    return this.get("price", params);
  }
}
