import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  GetUserInfoRequest,
  GetUserInfoResponse,
  PostImageRequest,
  PostImageResponse,
  TwitterEndpoints,
  TwitterService,
} from "../model/twitter";
import enhancedAxios from "./requestUtil";

function mapResponse<T>(input: AxiosResponse): T {
  const rawData = input.data;

  return {
    ...rawData,
  };
}

async function post<T>(
  route: TwitterEndpoints,
  args: AxiosRequestConfig = {},
): Promise<T> {
  const resp = await enhancedAxios.post(
    `/api/${TwitterEndpoints.MAIN}/${route}`,
    args,
  );

  return mapResponse<T>(resp);
}

async function get<T>(
  route: TwitterEndpoints,
  args: AxiosRequestConfig = {},
): Promise<T> {
  const resp = await enhancedAxios.get(
    `/api/${TwitterEndpoints.MAIN}/user/${route}`,
    args,
  );

  return mapResponse<T>(resp);
}

export class TwitterClient implements TwitterService {
  async postImage(body: PostImageRequest): Promise<PostImageResponse> {
    const postArgs: AxiosRequestConfig = {};

    postArgs.data = body;

    return await post<PostImageResponse>(TwitterEndpoints.POST_IMAGE, postArgs);
  }

  async getUserInfo(args?: GetUserInfoRequest): Promise<GetUserInfoResponse> {
    const getArgs: AxiosRequestConfig = {};

    getArgs.params = args; // user_id

    return await get<GetUserInfoResponse>(
      TwitterEndpoints.GET_USER_INFO,
      getArgs,
    );
  }
}
