import { useWallet } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { PUBLIC_KEY_LOCALSTORAGE_KEY } from "pages/_app";
import { useEffect } from "react";
import useClient from "src/hooks/useClient";
import { useSWRConfig } from "swr";

type WalletLocalStorageProps = {};

const WalletLocalStorage = (props: WalletLocalStorageProps) => {
  const { publicKey, disconnecting } = useWallet();
  const client = useClient("auth");
  const { mutate } = useSWRConfig();

  useEffect(() => {
    async function setPkInStorage(publicKey: PublicKey) {
      const stringPublicKey = publicKey.toString();

      return localStorage.setItem(PUBLIC_KEY_LOCALSTORAGE_KEY, stringPublicKey);
    }

    if (publicKey) {
      setPkInStorage(publicKey);
    }
  }, [publicKey]);

  useEffect(() => {
    if (!disconnecting) return;
    async function clearPkFromStorage() {
      localStorage.removeItem(PUBLIC_KEY_LOCALSTORAGE_KEY);
      await client.logout();
      mutate(["user-info"]);
    }
    clearPkFromStorage();
  }, [disconnecting]);

  return null;
};

export default WalletLocalStorage;
