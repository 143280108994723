import { AxiosRequestConfig } from "axios";
import { NftMarketService } from "model/nftMarket/service";
import {
  NftCollectionItemMetaDataResponseHM,
  NftCollectionItemMetaDataResponseME,
  NftCollectionListingsRequestParams,
  NftMarketBuyNowTransactionResponse,
  NftMarketDelistNowTransactionResponse,
  NftMarketListChangePriceTransactionResponse,
  NftMarketListNowTransactionResponse,
  NftMarketListTransactionResponse,
  NftMarketPlaceItemResponse,
  NftMetadataSummaryRequestParams,
  NftMetadataSummaryResponse,
  OffersReceivedMEResponse,
} from "model/nftMarket/types";
import enhancedAxios from "./requestUtil";

export class NftMarketClient implements NftMarketService {
  getNftCollectionItemMetaDataME(
    params: any,
  ): Promise<NftCollectionItemMetaDataResponseME> {
    return this.get<NftCollectionItemMetaDataResponseME>(
      "getNftCollectionItemMetaDataME",
      params,
    );
  }

  getNftCollectionItemMetaDataHM(
    params: any,
  ): Promise<NftCollectionItemMetaDataResponseHM> {
    return this.get<NftCollectionItemMetaDataResponseHM>(
      "getNftCollectionItemMetaDataHM",
      params,
    );
  }

  getCollectionListings(params: any): Promise<NftMarketPlaceItemResponse> {
    return this.get<NftMarketPlaceItemResponse>(
      "getCollectionListings",
      params,
    );
  }

  getWalletListings(params: any): Promise<NftMarketPlaceItemResponse> {
    return this.get<NftMarketPlaceItemResponse>("getWalletListings", params);
  }

  getOffersReceivedME(params: any): Promise<OffersReceivedMEResponse> {
    return this.get<OffersReceivedMEResponse>("getOffersReceivedME", params);
  }

  getBuyNowTxn(params: any): Promise<NftMarketBuyNowTransactionResponse> {
    return this.get<NftMarketBuyNowTransactionResponse>("getBuyNowTxn", params);
  }

  getListTxn(params: any): Promise<NftMarketListTransactionResponse> {
    return this.get<NftMarketListTransactionResponse>("getListTxn", params);
  }

  getListChangePriceTxn(
    params: any,
  ): Promise<NftMarketListChangePriceTransactionResponse> {
    return this.get<NftMarketListChangePriceTransactionResponse>(
      "getListChangePriceTxn",
      params,
    );
  }

  getListNowTxn(params: any): Promise<NftMarketListNowTransactionResponse> {
    return this.get<NftMarketListNowTransactionResponse>(
      "getListNowTxn",
      params,
    );
  }

  getDelistTxn(params: any): Promise<NftMarketDelistNowTransactionResponse> {
    return this.get<NftMarketDelistNowTransactionResponse>(
      "getDelistTxn",
      params,
    );
  }

  getCollectionListingsV2(args?: {
    params: NftCollectionListingsRequestParams;
  }): Promise<NftMarketPlaceItemResponse> {
    return this.post<NftMarketPlaceItemResponse>(
      "getCollectionListingsV2",
      args,
    );
  }

  getMetadataSummary(args?: {
    params: NftMetadataSummaryRequestParams;
  }): Promise<NftMetadataSummaryResponse> {
    return this.post<NftMetadataSummaryResponse>("getMetadataSummary", args);
  }

  async get<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.get(`/api/nft-markets/${route}`, args);
    return response.data;
  }

  /**
   * Use POST request to send JSON over the network request.
   */
  async post<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.post(
      `/api/nft-markets/${route}`,
      args,
    );
    return response.data;
  }
}
