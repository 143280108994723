import { AxiosRequestConfig } from "axios";
import { WalletMetaResponse } from "model/aggregations/wallets";
import { WalletService } from "model/aggregations/wallets/service";
import enhancedAxios from "./requestUtil";

export class WalletClient implements WalletService {
  listWalletMetas(args?: any): Promise<WalletMetaResponse> {
    return this.get<WalletMetaResponse>("listWalletMetas", args);
  }

  async get<T>(route: string, args: AxiosRequestConfig = {}): Promise<T> {
    const response = await enhancedAxios.get(
      `/api/aggregations/wallets/${route}`,
      args,
    );
    return response.data;
  }
}
