import { Program } from "typescript";
import { WalletLabel, WalletMeta } from "../wallets";
import { TimeRanges } from "..";
import { SortDirection } from "../nfts/constants";

export interface DefiProgram {
  // We do not include address since this info is not required and is IP.
  id: string;
  name: string;
  shortName?: string;
  slug: string;
  symbol?: string;
}

export interface DefiProgramSearchItem {
  name: string;
  slug: string;
  address: string;
}

export interface DefiProgramLeaderboardItem {
  name: string;
  slug: string;
  id: string;
  imageUri: string;
  totalUsdValue24h: number;
  totalUsdValue24hChange: number;
  topTokenSymbol: string;
  topTokenName: string;
  topTokenVolume1dUsd: number;
  activeUserCount: number;
  activeUserPercentChange: number;
  netNewUsers: number;
  netNewUsersPercentChange: number;
  topTokenMint: string;
  primaryOverlapProgramId: string;
  primaryOverlapProgramName: string;
  primaryOverlapProgramSlug: string;
  depositsUsd24Hr: number;
  withdrawalsUsd24Hr: number;
  swapsUsd24hr: number;
  borrowsUsd24hr: number;
  repaymentsUsd24hr: number;
  addLiquidityUsd24Hr: number;
  removeLiquidityUsd24Hr: number;
}

export enum ProgramListSort {
  TOTAL_USD_VALUE = "TOTAL_USD_VALUE",
  SWAPS = "SWAP",
  DEPOSITS = "DEPOSITS",
  WITHDRAWS = "WITHDRAWS",
  BORROWS = "BORROWS",
  REPAYMENTS = "REPAYMENTS",
  LIQUIDITY_ADDED = "LIQUIDITY_ADDED",
  LIQUIDITY_REMOVED = "LIQUIDITY_REMOVED",
  USERS = "USERS",
  NEW_USERS = "NEW_USERS",
}

export enum ProgramTopWalletsSort {
  TOTAL_USD_VALUE = "TOTAL_USD_VALUE",
  VOLUME = "VOLUME",
  PROPORTION_TOTAL_PROGRAM = "PROPORTION_TOTAL_PROGRAM",
}

export interface GetDefiProgramResponse {
  program: DefiProgram;
}

interface IdArg {
  id: string;
  slug?: string;
  address?: string;
}
interface SlugArg {
  slug: string;
  id?: string;
  address?: string;
}

interface AddressArg {
  address: string;
  slug?: string;
  id?: string;
}

export interface GetDefiProgramArgs {
  params: SlugArg | IdArg | AddressArg;
}

export interface GetDefiProgramStatsArgs {
  params: IdArg;
}

export interface GetDefiProgramsArgs {
  params: {
    // Results to return
    pageSize: number;
    // Token for getting the next page of results
    nextPageToken?: string;
    // Unimplemented
    nameFilter?: string;
  };
}

export interface ListDefiProgramsArgs {
  pageSize: string;
  sort?: ProgramListSort;
  sortDirection?: SortDirection;
}

export interface GetDefiProgramStatsResponse {
  program: DefiProgramLeaderboardItem;
}

export interface GetDefiProgramsResponse {
  programs: DefiProgramLeaderboardItem[];
}

export interface GetDefiProgramsForSearchResponse {
  programs: DefiProgramSearchItem[];
}

export interface GetDEXProgram {
  name: string;
  address: string;
  slug: string;
}

export interface GetSearchedDEXProgramsResponse {
  dexPrograms: GetDEXProgram[];
}

export interface GetLPProgramQueryResult {
  program_name: string;
  program_address: string;
  pool_name: string;
  token_a_slug: string;
  token_b_slug: string;
}

export interface GetLPProgram {
  programName: string;
  programAddress: string;
  poolName: string;
  tokenASlug: string;
  tokenBSlug: string;
}

export interface GetSearchedLPProgramsResponse {
  lpPrograms: GetLPProgram[];
}

export interface HelloMoonSupportedLPPrograms {
  program: string;
}

export interface ProgramVolumeNotableTransactionsRequestParams {
  // Filters results for the programId in question.
  //
  // Required.
  programId: string;

  // The time period for results.
  // change any to TimeRange type after fix
  timeRangeFilter: any;

  // Number of results
  pageSize?: number;
}

export interface ProgramVolumeNotableTransactionsResponse {
  // The transactions returned.
  results: ProgramVolumeNotableTransaction[];
}

/**
 * Response for Formatted VolumeNotableTransactions.
 */
export type ProgramVolumeNotableTransaction = {
  name: string;
  programId: string;
  mint: string;
  transactionTime: string;
  solVolume: string;
};

// Columns for the Program Overlap table
export type ProgramOverlap = {
  // program id
  programid: string;
  // percent
  users: string;
  //   name
  name: string;
  // slug
  slug: string;
};

export type ProgramOverlapRow = {
  symbol: string;
  name: string;
  users: string; // percent
};

// Results from the query
export type ProgramOverlapQueryResult = {
  // this is the program we pass thru params, and compare against
  a_programid: string;
  b_programid: string;
  num_overlap: string;
  num_total: string;
  percent_overlap: string;
  name: string;
  slug: string;
};

export type ProgramOverlapResponse = {
  data: ProgramOverlap[];
};

/*
 * ProgramActiveUsersOverTimeRequestParams
 * request params to get program active users over time
 */
export interface ProgramActiveUsersOverTimeRequestParams {
  // id of each program
  programId: string;
  // client passes timeRangeFilter: all-time, 1-month, 1-week, etc
  timeRangeFilter: string;
  // number of results
  pageSize?: number;
}

// Return Type from service layer
export interface ProgramActiveUserOverTimeResponse {
  results: ProgramActiveUserOverTime[];
}

// Return type for program active user over time SQL query
export type ProgramActiveUserOverTime = {
  programId: string;
  name: string;
  activeUserCount: number;
  time: string;
};

/**
 * ProgramNewUserOverTimeRequestParams
 * request params from client, with programId and timeRangeFilter
 */
export interface ProgramNewUserOverTimeRequestParams {
  // id of each program
  programId: string;
  // client passes timeRangeFilter: all-time, 1-month, 1-week, etc
  timeRangeFilter: string;
  // number of results
  pageSize?: number;
}

// Return Type from service layer
export interface ProgramNewUserOverTimeResponse {
  results: ProgramNewUserOverTime[];
}

export interface ProgramNewUserOverTime {
  programId: string;
  name: string;
  newUserCount: number;
  time: string;
}

export interface DefiProtocolByTopTokensVolumeRequestParams {
  // protocol filter used to get the program id
  protocolFilter: string;
}

export interface DefiProtocolByTopTokensVolumeResponse {
  results: DefiProgramByTopTokenVolume[];
}

export interface DefiProgramByTopTokenVolume {
  // name of token
  name: string;
  // volume token moved in last 24 hours
  vol24hr: string;
  // volume change of token in last 24 hours
  change24hr: string;
  //   slug for token link
  slug: string;
  //   mint of token
  mint: string;
}

export interface DefiProgramWalletSizeBaseParams {
  protocolFilter: string;
  // client passes timeRangeFilter: all-time, 1-month, 1-week, etc
  timeRangeFilter: string;
}

export interface DefiProtocolHr24VolumeByWalletSizeRequestParams
  extends DefiProgramWalletSizeBaseParams {}

export interface DefiProtocolDailyActiveUsersByWalletSizeRequestParams
  extends DefiProgramWalletSizeBaseParams {}

// cols for Protocol Community Wealth By Token table
export type DefiProtocolCommunityTokenWealth = {
  token: string;
  maxBalance: string;
  medianBalance: string;
  meanBalance: string;
  name: string;
};

// Results from the query
export type DefiProtocolCommunityTokenWealthQueryResult = {
  // programid is the protocol in consideration- i.e. passing thru params
  programid: string;
  mint: string;
  min_balance_usd: string;
  max_balance_usd: string;
  median_balance_usd: string;
  mean_balance_usd: string;
  num_holders: string;
  name: string;
};

export type DefiProgramOverlapParams = {
  programId: string;
};

export type DefiProtocolByTopTokensVolumeParams = {
  programId: string;
};

export type DefiProtocolCommunityTokenWealthParams = {
  programId: string;
};

export type DefiProtocolCommunityTokenWealthResponse = {
  data: DefiProtocolCommunityTokenWealth[];
};

/**
 * Response for Formatted ProtocolFeedByVolume.
 */
export interface ProtocolFeedByVolume {
  actor: WalletMeta;
  // time of token transaction
  transactionTime: string;
  // solana volume moved, when twitter user swapped protocol for solana
  solVolume: string;
  //   masked transactions id generated from hash, day + wallet_id + program_id
  maskedTransactionId: string;
}

export interface ProtocolFeedByVolumeRequestParams {
  // Protocol filter
  protocolFilter: string;

  // The time period for results.
  timeRangeFilter: TimeRanges;

  // Number of results
  pageSize?: number;

  // Results to skip
  offset?: number;
}

export interface ProtocolFeedByVolumeResponse {
  results: ProtocolFeedByVolume[];
}

export interface DefiMarketAggregates {
  swapVolume: number;
}

export interface DefiMarketActivityResponse {
  timeRange: TimeRanges;
  aggregates: DefiMarketAggregates;
}
