import { AxiosRequestConfig } from "axios";
import { FLAGS_ENDPOINTS } from "model/flags/constants";
import {
  FlagsService,
  GetAllFlagsResponse,
  GetFlagResponse,
} from "model/flags/service";
import enhancedAxios from "./requestUtil";

export const FLAGS_CLIENT_NAME = "flags" as const;

export class FlagsClientImpl implements FlagsService {
  async getAll(): Promise<GetAllFlagsResponse> {
    return this.get<GetAllFlagsResponse>("");
  }

  async getByFlagName(flagName: string): Promise<GetFlagResponse | null> {
    const args = { params: { flagName } };
    return this.get<GetFlagResponse | null>("getByFlagName", args);
  }

  async get<T>(
    route: FLAGS_ENDPOINTS | "" | "getByFlagName",
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await enhancedAxios.get(
      `/api/${FLAGS_ENDPOINTS.MAIN}/${route}`,
      args,
    );
    return response.data;
  }
}
