import { ApiKeysClient } from "clients/apiKeysClient";
import ApiUsageClient from "clients/apiUsageClient";
import { AuthClient } from "clients/authClient";
import { DataSubscriptionClient } from "clients/dataSubscriptionClient";
import { DefiAggregationsClient } from "clients/defiAggregations";
import { FlagsClientImpl, FLAGS_CLIENT_NAME } from "clients/flags";
import { SwapClient } from "clients/logSwaps";
import NftAggregationsClient from "clients/nftAggregations";
import { NftMarketClient } from "clients/nftMarket";
import PortfolioClient from "clients/portfolio";
import { SocialClient } from "clients/social";
import { WalletClient } from "clients/wallet";
import { WalletAuthClient } from "clients/walletAuth";
import { AuthContext } from "components/AuthProvider/AuthProvider";
import { createContext, useContext, useEffect } from "react";
import { isAuthTokenClient } from "src/utils/auth/authTokenClientUtil";
import { AggregationsClient } from "../../clients/aggregations";
import { DefiProgramsClient } from "../../clients/defiProgram";
import { DefiTokensClient } from "../../clients/defiTokens";
import { NftClient } from "../../clients/nfts";
import { SolanaClient } from "../../clients/solana";
import { TradingViewClientImpl } from "../../clients/tradingView";
import { TwitterClient } from "../../clients/twitter";
import type { Clients } from "../../model/clients";

export const clientsContext = createContext<Clients>({
  nftAggregations: new NftAggregationsClient(),
  defiAggregations: new DefiAggregationsClient(),
  aggregations: new AggregationsClient(),
  nft: new NftClient(),
  solana: new SolanaClient(),
  //   [CONSUMER_API_CLIENT_NAME]: new ConsumerApiClientImpl(),
  defiPrograms: new DefiProgramsClient(),
  [FLAGS_CLIENT_NAME]: new FlagsClientImpl(),
  //   issues: new IssuesClient(),
  //   stripe: new StripeClient(),
  twitter: new TwitterClient(),
  walletAuth: new WalletAuthClient(),
  defiTokens: new DefiTokensClient(),
  tradingView: new TradingViewClientImpl(),
  logSwaps: new SwapClient(),
  social: new SocialClient(),
  wallet: new WalletClient(),
  nftMarket: new NftMarketClient(),
  portfolio: new PortfolioClient(),
  dataSubscription: new DataSubscriptionClient(),
  auth: new AuthClient(),
  apiKeys: new ApiKeysClient(),
  apiUsage: new ApiUsageClient(),
});

export function useClient<ClientKey extends keyof Clients>(
  key: ClientKey,
): Clients[ClientKey] {
  const clients = useContext(clientsContext);
  const { token } = useContext(AuthContext);
  useEffect(() => {
    if (!clients) return;
    for (const prop in clients) {
      const client = clients[prop as keyof Clients];
      if (isAuthTokenClient(client)) client.setAuthToken(token);
    }
  }, [token]);
  return clients[key];
}

export default useClient;
