import { AxiosRequestConfig } from "axios";
import {
  ListApiUsagePerUser,
  ListApiUsagePerUserRequest,
} from "model/apiKey/ApiUsageModel";
import { BaseAuthTokenClient } from "./baseAuthTokenClient";
import enhancedAxios from "./requestUtil";

const _baseUri = "/api/apiUsage";

const _join = (base: string, route: string) =>
  route ? `${base}/${route}` : base;

class ApiUsageClient extends BaseAuthTokenClient {
  private async _get<T>(
    route: string,
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await enhancedAxios.get(
      _join(_baseUri, route),
      this.getAuthToken(args),
    );
    return response.data;
  }

  async getApiUsage(
    params: ListApiUsagePerUserRequest,
  ): Promise<ListApiUsagePerUser[]> {
    return await this._get("", { params });
  }
}

export default ApiUsageClient;
