import { ErrorBoundary, captureException } from "@sentry/nextjs";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import { WalletModalProvider } from "@solana/wallet-adapter-react-ui";
import {
  LedgerWalletAdapter,
  PhantomWalletAdapter,
  SolflareWalletAdapter,
  BackpackWalletAdapter,
} from "@solana/wallet-adapter-wallets";
import WalletLocalStorage from "components/WalletLocalStorage/WalletLocalStorage";
import { PropsWithChildren } from "react";
import { useRpcNode } from "src/hooks/useRpcNode";

const wallets = [
  new PhantomWalletAdapter(),
  new SolflareWalletAdapter(),
  new LedgerWalletAdapter(),
  new BackpackWalletAdapter(),
];

const WalletAdapterContext = ({ children }: PropsWithChildren) => {
  const endpoint = useRpcNode();

  try {
    new URL(endpoint);
  } catch (err) {
    captureException(err, { tags: { component: "WalletLoginError" } });
    return <>{children}</>;
  }

  return (
    <ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("component", "WalletAdapterContext");
      }}
    >
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletLocalStorage />
          <WalletModalProvider>{children}</WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ErrorBoundary>
  );
};

export default WalletAdapterContext;
