import type { AxiosRequestConfig } from "axios";
import type {
  PortfolioIBBResult,
  PortfolioLoanResult,
  PortfolioLPResult,
  PortfolioService,
  PortfolioWalletArgs,
} from "model/aggregations/portfolio";
import {
  BASE_URL,
  LIST_IBBS,
  LIST_LOANS,
  LIST_LPS,
} from "model/aggregations/portfolio/endpoints";
import enhancedAxios from "./requestUtil";

export default class PortfolioClient implements PortfolioService {
  private readonly baseEndpoint = BASE_URL;

  listLoans(args: PortfolioWalletArgs): Promise<PortfolioLoanResult> {
    return this.get<PortfolioLoanResult>(LIST_LOANS, {
      params: args,
    });
  }
  listInterestBearingBalances(
    args: PortfolioWalletArgs,
  ): Promise<PortfolioIBBResult> {
    return this.get<PortfolioIBBResult>(LIST_IBBS, {
      params: args,
    });
  }
  listLiquidityPools(args: PortfolioWalletArgs): Promise<PortfolioLPResult> {
    return this.get<PortfolioLPResult>(LIST_LPS, {
      params: args,
    });
  }

  private async get<T>(
    route: string,
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const response = await enhancedAxios.get(
      `${this.baseEndpoint}/${route}`,
      args,
    );
    return response.data;
  }
}
