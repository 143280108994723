import { AxiosRequestConfig, AxiosResponse } from "axios";
import {
  CONFIGURED_WALLETAUTH_ENDPOINTS,
  GenerateUserInput,
  LoginOrSignUpResponse,
  VerifyKeyResponse,
  WalletAuthService,
} from "model/walletAuth/service";
import enhancedAxios from "./requestUtil";

function mapResponse<T>(input: AxiosResponse): T {
  const rawData = input.data;

  return {
    ...rawData,
  };
}

async function post<T>(
  route: CONFIGURED_WALLETAUTH_ENDPOINTS,
  args: AxiosRequestConfig = {},
): Promise<T> {
  const resp = await enhancedAxios.post(`/api/wallet-auth/${route}`, args);

  return mapResponse<T>(resp);
}

const addData = (data: any) => {
  const postArgs: AxiosRequestConfig = {};

  postArgs.data = data;

  return postArgs;
};

export class WalletAuthClient implements WalletAuthService {
  async login(walletId: string): Promise<string> {
    const data = await post<string>(
      CONFIGURED_WALLETAUTH_ENDPOINTS.WALLET_LOGIN,
      addData({ publicKey: walletId }),
    );
    return data;
  }

  async verifyKey(secretKey: string): Promise<VerifyKeyResponse> {
    return await post<VerifyKeyResponse>(
      CONFIGURED_WALLETAUTH_ENDPOINTS.VERIFY_KEY,
      addData({ authKey: secretKey }),
    );
  }

  async loginOrSignUp(
    params: GenerateUserInput,
  ): Promise<LoginOrSignUpResponse> {
    return await post<LoginOrSignUpResponse>(
      CONFIGURED_WALLETAUTH_ENDPOINTS.CREATE_ACCOUNT,
      addData({ ...params }),
    );
  }
}
