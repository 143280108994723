import { Sorts, TimeRanges, TokenSummaryResult } from "..";
import { WalletMeta } from "../wallets";

export type ListTokensResponse = {
  results: TokenSummaryResult[];
  nextPageToken: string;
};

export type TotalValueMovedOnChainRequestParams = {
  includeFailed?: string | boolean;
  timeRangeFilter: string;
  tokenFilter?: string;
  protocolFilter?: string;
};

export interface TokenNotableTransactionsRequestParams {
  // Filters results for the tokenKey in question.
  //
  // Required.
  tokenFilter: string;

  // The time period for results.
  // change any to TimeRange type after fix
  timeRangeFilter: any;

  // Number of results
  pageSize?: number;
}

/**
 * Response for TokenNotableTransactions.
 */
export interface TokenPriceNotableTransactionsResponse {
  // The transactions returned.
  results: TokenNotableTransaction[];
}

/**
 * Response for Formatted TokenNotableTransactions.
 */
export type TokenNotableTransaction = {
  // unexposed wallet name, since twitter_handler's field might be empty
  name: string;
  // name of token, used to get tokenKey
  tokenName: string;
  // do not expose mint
  mint: string;
  // time of token transaction
  transactionTime: string;
  // conversion price when token was swapped to usd
  price: string;
};

export interface TokenFeedNotableTransactionsRequestParams {
  tokenFilter: string;
  // The time period for results.
  // change any to TimeRange type after fix
  timeRangeFilter: any;
  // Number of results
  pageSize?: number;
  //   used to pull latest feed item off list
  offset?: number;
}

export interface TokenFeedNotableTransactionsResponse {
  // The transactions returned.
  results: TokenFeedNotableTransaction[];
}

export enum TokenTransactionType {
  UNKNOWN = "UNKNOWN",
  BUY = "BUY",
  SELL = "SELL",
}

/**
 * Response for Formatted TokenFeedNotableTransactions.
 */
export interface TokenFeedNotableTransaction {
  actor: WalletMeta;
  // time of token transaction
  transactionTime: string;
  // USDC:X exchange rate of the transaction of currency X.
  // This could be the price paid (if bought X) or price yielded (if sold X).
  // E.g. bought 10 RAY at 4 USDC / RAY
  // E.g. sold 10 RAY at 4 USDC / RAY
  price: string;
  // Masked transaction id
  maskedTransactionId: string;
  // The action taken
  type: TokenTransactionType;
}
