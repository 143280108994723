import {
  TradingViewClient,
  GetChartResponse,
  PostChartResponse,
  TRADING_VIEW_ENDPOINTS,
  PostChartRequest,
  PostChartParams,
  GetAllChartsResponse,
} from "../model/tradingView";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import enhancedAxios from "./requestUtil";

export const TRADING_VIEW_CLIENT_NAME = "tradingView" as const;

function mapResponse<T>(input: AxiosResponse): T {
  const rawData = input.data;

  return {
    ...rawData,
  };
}

export class TradingViewClientImpl implements TradingViewClient {
  async postChart(
    body: PostChartRequest,
    params: PostChartParams,
  ): Promise<PostChartResponse> {
    return this.post<PostChartResponse>(
      TRADING_VIEW_ENDPOINTS.POST_CHART,
      body,
      params,
    );
  }

  async getAllCharts(
    clientId: string,
    userId: string,
    symbol: string,
  ): Promise<GetAllChartsResponse> {
    const getArgs: AxiosRequestConfig = {};

    getArgs.params = { clientId, userId, symbol };

    const response = await this.get<GetAllChartsResponse>(
      TRADING_VIEW_ENDPOINTS.GET_ALL_CHARTS,
      getArgs,
    );

    return response;
  }

  async getChart(chartId: string): Promise<GetChartResponse> {
    const getArgs: AxiosRequestConfig = {};

    getArgs.params = { chartId };

    const response = await this.get<GetChartResponse>(
      TRADING_VIEW_ENDPOINTS.GET_CHART,
      getArgs,
    );

    return response;
  }

  async post<T>(route: string, body: any, params: object) {
    const resp = await enhancedAxios.post(
      `/api/${TRADING_VIEW_ENDPOINTS.MAIN}/${route}`,
      body,
      {
        params,
      },
    );

    return mapResponse<T>(resp);
  }

  async get<T>(
    route: TRADING_VIEW_ENDPOINTS,
    args: AxiosRequestConfig = {},
  ): Promise<T> {
    const resp = await enhancedAxios.get(
      `/api/${TRADING_VIEW_ENDPOINTS.MAIN}/${route}`,
      args,
    );

    return mapResponse<T>(resp);
  }
}
