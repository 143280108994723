import axios, { AxiosResponse } from "axios";
import { JupiterSwapService } from "model/swaps/log/service";
import { CONFIGURED_SWAP_ENDPOINTS, TxnIdArgs } from "model/swaps/log/types";
import Configuration from "src/configuration";

const config = Configuration();
/**
 * Just logging a swap to our dynamo table
 */
export class SwapClient implements JupiterSwapService {
  async logSwap(args: TxnIdArgs): Promise<boolean> {
    const response = await this.get(
      CONFIGURED_SWAP_ENDPOINTS.JUPITER_SWAPS,
      args,
    );
    return response.status == 200;
  }
  async get(route: string, body: any) {
    return axios
      .post(`${config.NEXT_PUBLIC.API_URL}/api/swaps/${route}`, body)
      .then((resp: AxiosResponse) => resp);
  }
}
